import React from 'react';
import { sortBy } from 'lodash';

export function optionsForEnum(enums, placeholder = '') {
  return !!enums
    ? [
      <option value="" key="" disabled>
        {placeholder}
      </option>,
    ].concat(
      enums.map((e) => (
        <option value={e.id} key={e.name}>
          {e.name}
        </option>
      ))
    )
    : [
      <option value="" key="">
        {placeholder}
      </option>,
    ];
}

export function multiSelectOptionsForEnum(enums) {
  return !!enums
    ? enums.map((e) => {
      return { value: e.id, label: e.name };
    })
    : {};
}

export function otherLast(enums) {
  return sortBy(enums, (e) => (e.name === 'Other' || e.name === 'Other Type' ? 1 : 0));
}

export const getEnumName = (enums, enumId) => {
  return enums?.find(({ id }) => id == enumId)?.name;
};

export const isOtherPresent = (enums, enumId) => {
  if (!enums && !enumId) return false;
  let otherId = enums.find((it) => it.name === 'Other')?.id || '';
  return Array.isArray(enumId)
    ? enumId.includes(otherId) || enumId.includes(otherId.toString())
    : enumId == otherId;
};

export const getJobDescriptionNames = (jobs, ids) => {
  return !!jobs && !!ids
    ? `${ids
      .map((jobId) => {
        return jobs.find(({ id }) => id == jobId)?.name;
      })
      .join(', ')}`
    : '';
};

export const getSetting = ({ hasSchool } = {}) => {
  if (!!hasSchool)
    return [
      { id: 0, name: 'Industrial' },
      { id: 1, name: 'Medical' },
      { id: 2, name: 'Performing Arts' },
      { id: 3, name: 'School' },
      { id: 4, name: 'Sports' },
    ];

  return [
    { id: 4, name: 'Sports' },
    { id: 0, name: 'Industrial' },
    { id: 1, name: 'Medical' },
    { id: 2, name: 'Performing Arts' },
  ];
};

export const getSettingName = (settingId) => {
  return getSetting({ hasSchool: true })?.find(({ id }) => id === parseInt(settingId))?.name;
};

export const getSettingDetailsName = (settingDetailId) => {
  let i = 0;
  while (i < 5) {
    const settingDetails = getSettingDetail(i);
    const idx = settingDetails.findIndex(({ id }) => id === settingDetailId);
    if (idx > -1) {
      return settingDetails[idx].name;
    }
    i++;
  }
};

export const getSettingDetail = (settingId) => {
  const settingName = getSettingName(settingId);
  switch (settingName) {
    case 'School':
      return [
        { id: 32, name: 'Athletic Training Room Coverage' },
        { id: 0, name: 'Camp' },
        { id: 1, name: 'Camp Overnight' },
        { id: 2, name: 'Clinic' },
        { id: 3, name: 'Game' },
        { id: 4, name: 'League' },
        { id: 5, name: 'Match' },
        { id: 6, name: 'Meet' },
        { id: 7, name: 'Practice' },
        { id: 8, name: 'Showcase' },
        { id: 9, name: 'Tournament' },
        { id: 58, name: 'Other' },
      ];
    case 'Industrial':
      return [
        { id: 10, name: 'Bank' },
        { id: 12, name: 'Construction Site' },
        { id: 13, name: 'Corporate Building' },
        { id: 14, name: 'Distribution Center' },
        { id: 15, name: 'Food Production Facility' },
        { id: 16, name: 'Hospitality Facility' },
        { id: 17, name: 'Manufacturing Facility' },
        { id: 18, name: 'Military Facility' },
        { id: 19, name: 'Public Safety Facility' },
        { id: 58, name: 'Other' },
      ];
    case 'Medical':
      return [
        { id: 20, name: 'Chiropractic Clinic' },
        { id: 21, name: 'Hospital' },
        { id: 22, name: 'Orthopedic Clinic' },
        { id: 23, name: 'Physical Therapy Clinic' },
        { id: 24, name: 'Physician Practice' },
        { id: 25, name: 'Urgent Care' },
        { id: 58, name: 'Other' },
      ];
    case 'Performing Arts':
      return [
        { id: 26, name: 'Circus' },
        { id: 27, name: 'Concert Hall' },
        { id: 28, name: 'Festival' },
        { id: 29, name: 'Production Set' },
        { id: 30, name: 'Theatre' },
        { id: 31, name: 'Training Center' },
        { id: 58, name: 'Other' },
      ];
    case 'Sports':
      return [
        { id: 32, name: 'Athletic Training Room Coverage' },
        { id: 0, name: 'Camp' },
        { id: 2, name: 'Clinic' },
        { id: 3, name: 'Game' },
        { id: 4, name: 'League' },
        { id: 5, name: 'Match' },
        { id: 6, name: 'Meet' },
        { id: 7, name: 'Practice' },
        { id: 9, name: 'Tournament' },
      ];
    default:
      return [];
  }
};

export const getJobDescription = (settingId = '', settingDetailId = '') => {
  const settingName = getSettingName(settingId);
  switch (settingName) {
    case 'Medical':
    case 'Sports':
    case 'Performing Arts':
    case 'School':
      return [
        { id: 0, name: 'Athletic Training Services' },
        { id: 1, name: 'COVID Screening' },
        { id: 2, name: 'COVID Testing' },
        { id: 18, name: 'Other' },
      ];
    case 'Industrial':
      return [
        { id: 0, name: 'Athletic Training Services' },
        { id: 1, name: 'COVID Screening' },
        { id: 2, name: 'COVID Testing' },
        { id: 3, name: 'Ergonomics' },
        { id: 18, name: 'Other' },
      ];
    default:
      return [];
  }
};

export const sportsFilter = (sports) => {
  return sports.filter((item) => {
    return item.name !== 'Industrial';
  });
};

export const SHIFT_CANCELLATION_REASONS = [
  { id: 'Shift Canceled', name: 'Shift Canceled' },
  { id: 'Job Canceled', name: 'Job Canceled' },
  { id: 'Shifts Canceled', name: 'Shifts Canceled' },
  { id: 'Position Filled', name: 'Position Filled' },
  { id: 'Incorrectly Posted', name: 'Incorrectly Posted' },
  { id: 'No Applicants', name: 'No Applicants' },
];

export const SECONDARY_REASONS = {
  'Shift Canceled': [
    { id: 'Weather', name: 'Weather' },
    { id: 'Staff no longer needed', name: 'Staff no longer needed' },
    { id: 'Other', name: 'Other' },
  ],
  'Job Canceled': [
    { id: 'Weather', name: 'Weather' },
    { id: 'Staff no longer needed', name: 'Staff no longer needed' },
    { id: 'Other', name: 'Other' },
  ],
  'Shifts Canceled': [
    { id: 'Weather', name: 'Weather' },
    { id: 'Staff no longer needed', name: 'Staff no longer needed' },
    { id: 'Other', name: 'Other' },
  ],
  'Position Filled': [
    { id: 'Staffed through Facility/Venue', name: 'Staffed through Facility/Venue' },
    { id: 'Staffed using another resource', name: 'Staffed using another resource' },
    { id: 'Other', name: 'Other' },
  ],
};

export const ORGANIZATION_TYPES = [
  { id: 5, name: 'Facility' },
  { id: 0, name: 'Industrial' },
  { id: 1, name: 'Medical' },
  { id: 2, name: 'Performing Arts' },
  { id: 3, name: 'School' },
  { id: 4, name: 'Sports' },
];

export const CREDENTIALS_TO_REPORT = [
  { id: 'National Certification', name: 'National Certification' },
  { id: 'State Certification or License', name: 'State Certification or License' },
  { id: 'Professional Liability Insurance', name: 'Professional Liability Insurance' },
  { id: 'Background Check', name: 'Background Check' },
  { id: 'Child Abuse Clearance', name: 'Child Abuse Clearance' },
  { id: 'NATA Membership', name: 'NATA Membership' },
  { id: 'NPI Number', name: 'NPI Number' },
  { id: 'Resume', name: 'Resume' },
  { id: 'Standing Physician Orders', name: 'Standing Physician Orders' },
  { id: 'U.S. Center for SafeSport', name: 'U.S. Center for SafeSport' },
];

export const REPORT_ISSUE_REASONS = [
  { id: 'Expired credential', name: 'Expired credential' },
  { id: 'Incorrect document', name: 'Incorrect document' },
  { id: 'Other', name: 'Other' },
];
