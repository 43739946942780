import React, { useState } from 'react';
import { SESSION_EMAIL_KEY } from 'services/SessionService';
import HorizontalRule from 'components/HorizontalRule';
import { trackMixpanelPageView } from 'helpers/post';

const LoginForm = (props) => {
  const [email, setEmail] = useState(
    new URLSearchParams(props.location.search).get('email') ||
      localStorage.getItem(SESSION_EMAIL_KEY) ||
      ''
  );
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({ email, password, rememberMe });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleShowPasswordChange = (e) => {
    setShowPassword((prev) => !prev);
  };

  const isEventOperator = () => {
    return !['Parent', 'Patient'].includes(props.role);
  };

  return (
    <form className="v2loginForm nimbus-regular-font" onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <div className="input-group">
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
      </div>
      <label htmlFor="password">Password</label>
      <div className="input-group">
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control password-input"
          id="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <i
          className={`show-password fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
          aria-hidden="true"
          onClick={handleShowPasswordChange}
        ></i>
      </div>
      <div className="form-group remember-me">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="rememberMe"
            checked={rememberMe}
            onChange={handleRememberMeChange}
          />
          <label className="custom-control-label nimbus-regular-font" htmlFor="rememberMe">
            Remember Me
          </label>
        </div>
      </div>
      <button type="submit" className="btn plain-button gotham-bold-font login">
        Log In
      </button>
      {props.from === 'login' && (
        <React.Fragment>
          <HorizontalRule dashed />
          <div
            className="login-footer gotham-regular-font"
            style={{
              justifyContent: isEventOperator() ? 'space-between' : 'flex-end',
            }}
          >
            {isEventOperator() && (
              <a
                className="new-account"
                onClick={() => trackMixpanelPageView('NewToGo4Click')}
                href="/v2/create-event/intro"
              >
                NEW TO GO4? CLICK HERE
              </a>
            )}
            <a className="forgot-password" href="/v2/forgot-password">
              Forgot Password?
            </a>
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

export default LoginForm;
