// @flow
import ApiService from './ApiService';
import { ShiftSerializer, EventSerializer, UserSerializer } from './serializers';
import { isEmpty } from 'lodash';

class ShiftsService extends ApiService {
  constructor(session: mixed, eventId: number) {
    super(session);
    this.eventId = eventId;
    this.url = `/events/${eventId}/shifts`;
    this.v2Url = '/v2/shifts';
  }

  // get(id) {
  //   return super.get(`${this.url}/${id}.json`,
  //     (response) => EventLocationSerializer.deserialize(response));
  // }

  getAll() {
    return super.get(`${this.url}.json`, (response) => response.map(ShiftSerializer.deserialize));
  }

  index(params) {
    return super.search(`${this.v2Url}.json`, params, (response) => {
      return {
        per_page: response.per_page,
        total_pages: response.total_pages,
        current_page: response.current_page,
        events: this.regroupShiftsByEvent(response.shifts, response.athletic_trainers),
        athletic_trainers: response.athletic_trainers.map(UserSerializer.deserializeHCT),
      };
    });
  }

  create(shifts: Object[]) {
    return super.post(
      `${this.url}.json`,
      { shifts: shifts.map(ShiftSerializer.serialize) },
      (response) => response.map(ShiftSerializer.deserialize)
    );
  }

  // update(eventLocation) {
  //   return super.patch(`${this.url}/${eventLocation.id}.json`, EventLocationSerializer.serialize(eventLocation),
  //     (response) => EventLocationSerializer.deserialize(response));
  // }

  delete(shift, reason) {
    return super.delete(
      `${this.url}/${shift.id}.json?reason=${encodeURIComponent(reason)}`,
      () => true
    );
  }

  regroupShiftsByEvent(shifts, ats) {
    if (shifts.length === 0) return [];

    let eventIdGroup;
    const events = shifts.reduce((result, shift) => {
      if (eventIdGroup !== shift.event.id) {
        result.push(shift.event);
        eventIdGroup = shift.event.id;
      }

      const { event, ...sh } = shift;

      if (isEmpty(result[result.length - 1]['shifts']))
        result[result.length - 1]['shifts'] = [shift];
      else result[result.length - 1].shifts.push(shift);

      return result;
    }, []);

    return events.map((event) => EventSerializer.deserialize(event, ats));
  }
}

export default ShiftsService;
