import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LearnMoreModal from './LearnMoreModal/LearnMoreModal';
import { trackMixpanelPageView } from 'helpers/post';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import HorizontalRule from 'components/HorizontalRule';

class EventIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learnMoreModalOpen: false,
    };
  }

  toggleLearnMoreModal = () => {
    this.setState((prevState) => ({
      learnMoreModalOpen: !prevState.learnMoreModalOpen,
    }));
  };

  onImReady = () => {
    this.props.history.push('type');
    trackMixpanelPageView('ImReadyToPostClick', this.props.user);
  };

  onImNotReady = () => {
    this.toggleLearnMoreModal();
    trackMixpanelPageView('NotReadyToPostClick', this.props.user);
  };

  render() {
    return (
      <div className="eventIntro">
        <main className="content">
          <header>
            <div className="screen-title">Before you get started</div>
          </header>
          <div className="content-wrapper">
            <div className="intro-text">
              <div className="list-container">
                <ul>
                  <li>ATs are certified and insured</li>
                  <li>No listing fees</li>
                  <li>You set the pay rate</li>
                  <li>You choose the applicants</li>
                  <li>Pay only after an AT works</li>
                </ul>
                <Button
                  onClick={this.onImReady}
                  className={classNames('ready-button gotham-medium-font')}
                  block
                >
                  <div>I'M READY TO POST MY JOB</div>
                </Button>
                <Button
                  className="not-ready-link gotham-medium-font"
                  onClick={this.onImNotReady}
                >
                  <div>NOT READY TO POST? CLICK HERE</div>
                </Button>
              </div>
            </div>
            <div className="intro-video">
              <iframe
                src="https://www.youtube.com/embed/JmuF-Z2_qUM?si=hltAI8gjXMjxeRCX&amp;controls=0&amp;?&autoplay=1&amp;"
                title="YouTube video player"
                frameborder="0"
                allow="autoplay"
                referrerPolicy="strict-origin-when-cross-origin"
                reg
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <HorizontalRule margin='10px' />
        </main>
        <LearnMoreModal isOpen={this.state.learnMoreModalOpen} toggle={this.toggleLearnMoreModal} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
});

export default withRouter(connect(mapStateToProps)(EventIntro));
