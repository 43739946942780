// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputOrFlatpickr from 'components/InputOrFlatpickr';
import {
  reuseLocationChanged,
  clearInvalidMSAMessage,
  clearNoZipcodeMessage,
  addReportToContact,
  removeReportToContact,
  reportToContactChanged,
  reuseOnsiteContactsChanged,
} from 'redux/ducks/CreateEvent/Shifts';
import classnames from 'classnames';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { GoogleSerializer } from 'services/serializers';

import type { Dispatch } from 'redux/ducks/helpers';
import type { AppState } from 'redux/ducks';
import type { ShiftFormType } from 'redux/ducks/CreateEvent/Shifts';
import zipcode_to_timezone from 'zipcode-to-timezone';
import moment from 'moment-timezone';
import { Input } from 'reactstrap';
import ShiftRepeatModal from '../ShiftPanelList/ShiftRepeatModal';
import { isEventCamp } from 'helpers/isEventCamp';
import { isEventIndustrial } from 'helpers/isEventIndustrial';
import ReportToContactList from '../ReportToContactList';
import { Link } from 'react-router-dom';
import { isContractEo } from 'helpers/contracts';
import ContractPartialForm from './ContractPartialForm';

declare var google: any;

type ShiftFormProps = {
  onNewAddressInput: Function,
  shift: ShiftFormType,
  onFieldChange: (string) => (Object) => void,
  wasValidated: boolean,
  onGooglePlaceSelected: Function,

  addressFieldDisabled: boolean,
  onReuseLocationChanged: Function,
  showReuseLocationCheckbox: boolean,

  onsiteContactsDisabled: boolean,
  onReuseOnsiteContactsChanged: Function,
  showReuseOnsiteContactsCheckbox: boolean,

  invalidLocationSelected: boolean,
  noZipcodeSelected: boolean,
  onModalClear: Function,
  onModalClearNoZipcode: Function,
};

type ShiftFormState = {
  wasFieldValidated: Object,
};

class ShiftForm extends Component<ShiftFormProps, ShiftFormState> {
  constructor() {
    super();

    this.state = {
      shiftVerySoonModal: false,
      wasFieldValidated: {
        start: false,
        end: false,
        capacity: false,
        address: false,
        name: false,
        details: false,
        notes: false,
        responsibilities: false,
        approxHours: false,
      },
    };

    this.addressRef = React.createRef();
  }

  toggleLeadModalOpen = () => {
    this.setState((prevState) => ({
      ...prevState,
      shiftVerySoonModal: !prevState.shiftVerySoonModal,
    }));
  };

  onFieldBlur = (field: string) => {
    if (!this.state.wasFieldValidated[field]) {
      this.setState((prevState) => {
        return {
          ...prevState,
          wasFieldValidated: {
            ...prevState.wasFieldValidated,
            [field]: true,
          },
        };
      });
    }

    if (field === 'end' && moment(this.props.shift?.start).isBefore(moment().add(14, 'days'))) {
      this.toggleLeadModalOpen();
    }
  };

  showError = (fieldName: string) => {
    const { shift, wasValidated } = this.props;
    return (
      (wasValidated || this.state.wasFieldValidated[fieldName]) && !!shift.errorMessages[fieldName]
    );
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && !(e.target && e.target.tagName.toLowerCase() === 'textarea')) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  onAddContact = (shift) => (event) => {
    event.preventDefault();
    this.props.onAddReportToContact(shift.idx)();
  };

  onKeepRate = () => {
    this.toggleLeadModalOpen();
  };

  onEditRate = () => {
    this.toggleLeadModalOpen();
    this.props.goToChangePayRate();
  };

  render() {
    const {
      user,
      shift,
      onFieldChange,
      wasValidated,
      addressFieldDisabled,
      onNewAddressInput,
      onReuseLocationChanged,
      showReuseLocationCheckbox,
      onReuseOnsiteContactsChanged,
      showReuseOnsiteContactCheckbox,
      onsiteContactsDisabled,
      invalidLocationSelected,
      noZipcodeSelected,
      onModalClear,
      onModalClearNoZipcode,
      onRemoveReportToContact,
      onChangeReportToContact,
      onAddReportToContact,
    } = this.props;

    return (
      <div className="shiftForm">
        <form noValidate onKeyPress={this.handleKeyPress} onSubmit={(e) => e.preventDefault()}>
          {!isContractEo(user.email) && (
            <div className="row">
              <div className="form-group col-md-3">
                <label htmlFor="date">Start Time of Shift</label>
                <div className="input-group">
                  <InputOrFlatpickr
                    type="datetime"
                    name="start"
                    value={shift.start}
                    options={dateTimePickerOptions}
                    onChange={onFieldChange('start')}
                    onBlur={this.onFieldBlur.bind(this, 'start')}
                    className={classnames(
                      'form-control',
                      // TODO fix internal react-flatpickr
                      {
                        'is-invalid':
                          this.showError('start') &&
                          (this.state.wasFieldValidated['end'] || wasValidated),
                      }
                    )}
                    placeholder="Pick start time"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt"></i>
                    </span>
                  </div>
                  <div className="invalid-feedback">{shift.errorMessages['start']}</div>
                </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="end">End Time of Shift</label>
                <div className="input-group">
                  <InputOrFlatpickr
                    name="end"
                    value={shift.end}
                    options={dateTimePickerOptions}
                    type="datetime"
                    onChange={onFieldChange('end')}
                    onBlur={this.onFieldBlur.bind(this, 'end')}
                    className={classnames('form-control', { 'is-invalid': this.showError('end') })}
                    placeholder="Pick end time"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt"></i>
                    </span>
                  </div>
                  <div className="invalid-feedback">{shift.errorMessages['end']}</div>
                </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="timezone">Time Zone</label>
                <div className="input-group">
                  <input
                    name="timezone"
                    value={
                      zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) ||
                      moment.tz.guess()
                    }
                    className={classnames('form-control')}
                    placeholder="set address first"
                    disabled
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="capacity">Staff Needed for Shift</label>
                <div className="input-group">
                  <input
                    type="number"
                    name="capacity"
                    value={shift.capacity}
                    onChange={onFieldChange('capacity')}
                    onBlur={this.onFieldBlur.bind(this, 'capacity')}
                    className={classnames('form-control', {
                      'is-invalid': this.showError('capacity'),
                    })}
                    placeholder="1"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <div className="invalid-feedback">{shift.errorMessages['capacity']}</div>
                </div>
              </div>
            </div>
          )}
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="form-group col-md-9">
              <label htmlFor="address">Address</label>
              <input
                name="address"
                className={classnames('form-control', { 'is-invalid': this.showError('address') })}
                placeholder="e.g. 123 Any Street"
                ref={this.addressRef}
                value={shift.eventLocation.formattedAddress}
                disabled={addressFieldDisabled && !showReuseLocationCheckbox}
                autoComplete="off"
                onChange={onNewAddressInput}
                onBlur={this.onFieldBlur.bind(this, 'address')}
              />
              <div className="invalid-feedback">{shift.errorMessages['address']}</div>
            </div>
            {showReuseLocationCheckbox && (
              <div className="form-group col location-check-col">
                <div className="custom-control custom-checkbox">
                  <input
                    checked={addressFieldDisabled}
                    onChange={onReuseLocationChanged}
                    type="checkbox"
                    className="custom-control-input"
                    id="reuseLocation"
                  />
                  <label className="custom-control-label" htmlFor="reuseLocation">
                    Use this location for all shifts
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="row countType">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name of Venue</label>
              <input
                type="text"
                name="name"
                value={shift.eventLocation.name}
                disabled={addressFieldDisabled && !showReuseLocationCheckbox}
                onChange={onFieldChange('name')}
                onBlur={this.onFieldBlur.bind(this, 'name')}
                className={classnames('form-control', { 'is-invalid': this.showError('name') })}
                placeholder="e.g. The Go4 Dome"
              />
              <div className="invalid-feedback">{shift.errorMessages['name']}</div>
            </div>
            {!this.props.isEventIndustrial && (
              <div className="form-group col-md-3">
                <label htmlFor="countValue">Number of Playing Surface(s)</label>
                <input
                  type="number"
                  name="countValue"
                  value={shift.countValue}
                  onChange={onFieldChange('countValue')}
                  onBlur={this.onFieldBlur.bind(this, 'countValue')}
                  className={classnames('form-control', {
                    'is-invalid': this.showError('countValue'),
                  })}
                  placeholder="e.g. 2"
                />
                <div className="invalid-feedback">{shift.errorMessages['countValue']}</div>
              </div>
            )}
            {!this.props.isEventIndustrial && (
              <div className="form-group col-md-3">
                <label htmlFor="countType">Type of Playing Surface(s)</label>
                <select
                  name="countType"
                  value={shift.countType}
                  onChange={onFieldChange('countType')}
                  onBlur={this.onFieldBlur.bind(this, 'countType')}
                  className={classnames('form-control', {
                    'is-invalid': this.showError('countType'),
                  })}
                >
                  <option value="noFields">Fields</option>
                  <option value="noMats">Mats</option>
                  <option value="noCourts">Courts</option>
                  <option value="noRinks">Rinks</option>
                </select>
                <div className="invalid-feedback">{shift.errorMessages['countType']}</div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="notes">
                {isContractEo(user.email) ? 'Overview' : 'Shift Details'}
              </label>
              <textarea
                className="form-control"
                style={{ width: '100%', minHeight: '120px', border: '1px solid #b9b6ba' }}
                type="text"
                name="notes"
                value={shift.notes}
                rows={4}
                placeholder={
                  'Enter specific shift information, such as schedule details, items provided, what to bring, where to park, etc.'
                }
                onChange={onFieldChange('notes')}
              />
            </div>
            <div className="invalid-feedback">{shift.errorMessages['notes']}</div>
          </div>
          {isContractEo(user.email) && (
            <ContractPartialForm
              sports={this.props.sports}
              shift={shift}
              onFieldChange={onFieldChange}
              showError={this.showError}
              onFieldBlur={this.onFieldBlur}
            />
          )}
          {!isContractEo(user.email) && (
            <ReportToContactList
              showReuseOnsiteContactCheckbox={showReuseOnsiteContactCheckbox}
              onsiteContactsDisabled={onsiteContactsDisabled}
              onReuseOnsiteContactChanged={onReuseOnsiteContactsChanged}
              disabled={onsiteContactsDisabled && !showReuseOnsiteContactCheckbox}
              contacts={shift.reportToContacts}
              idx={shift.idx}
              errors={
                shift.errorMessages['reportToContacts'] ??
                new Array(shift.reportToContacts.length).fill({})
              }
              emptyError={shift.errorMessages['emptyReportToContacts']}
              wasValidated={wasValidated}
              removeContact={onRemoveReportToContact(shift.idx)}
              changeContact={onChangeReportToContact(shift.idx)}
              addContact={onAddReportToContact(shift.idx)}
            />
          )}
          {!isContractEo(user.email) && (
            <div className="d-flex mt-3 button-row">
              {!(onsiteContactsDisabled && !showReuseOnsiteContactCheckbox) && (
                <button
                  className="btn btn-lg btn-primary add-report-contact-btn"
                  onClick={this.onAddContact(shift)}
                >
                  Add Job Contact
                </button>
              )}
              {this.props.editMode && shift.eventLocation.id && (
                <div className="d-flex flex-row-reverse">
                  <Link
                    to={`shifts/${shift.id}/emergency-action-plan/edit`}
                    onClick={this.props.onEditEap}
                    className="btn btn-lg btn-primary eap-link"
                  >
                    EDIT EAP
                  </Link>
                </div>
              )}
            </div>
          )}
          {this.props.editMode === false && !isContractEo(user.email) && (
            <div className="row">
              <div className="form-group col-md-3 offset-md-9">
                <ShiftRepeatModal
                  shiftId={this.props.shiftId}
                  onRef={(instance) => {
                    this.toggleShiftModal = instance;
                  }}
                ></ShiftRepeatModal>
                <Input
                  type="select"
                  name="select"
                  onChange={(elem) => {
                    if (elem.target.value == 1 && !!shift.start && !!shift.end) {
                      this.toggleShiftModal.toggle();
                    } else {
                      elem.target.value = 0;
                    }
                  }}
                >
                  <option value="0">Does Not Repeat</option>
                  <option value="1">Repeats</option>
                </Input>
              </div>
            </div>
          )}
        </form>
        <Modal isOpen={invalidLocationSelected} toggle={onModalClear}>
          <ModalHeader toggle={onModalClear}>We're Not In Your Area Just Yet</ModalHeader>
          <ModalBody style={{ textAlign: 'center' }}>
            <p>Sorry, Go4 is not available in your area yet.</p>
            <p>But we are working hard to get there!</p>
            <p>Thank you for your patience.</p>
            <p>- Ellis</p>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'flex-end' }}>
            <button className="btn btn-lg btn-primary" onClick={onModalClear}>
              OK, Got it
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={noZipcodeSelected} toggle={onModalClear}>
          <ModalHeader toggle={onModalClear}>No Zip Code Selected</ModalHeader>
          <ModalBody style={{ textAlign: 'center' }}>
            <p>Please enter a location with a valid zip code</p>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'flex-end' }}>
            <button className="btn btn-lg btn-primary" onClick={onModalClearNoZipcode}>
              OK, Got it
            </button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.shiftVerySoonModal}
          toggle={this.toggleLeadModalOpen}
          className="confirmation-modal-content"
        >
          <ModalHeader toggle={this.toggleLeadModalOpen}>Late Posting Alert</ModalHeader>
          <ModalBody>
            You are posting a shift within 14 days of its start date. This is considered a late
            posting and may be harder to get filled. We suggest reviewing your rate and posting at
            the high end of your budget to attract applicants.
          </ModalBody>
          <ModalFooter
            style={{
              justifyContent: 'space-between',
              paddingRight: '40px',
              paddingLeft: '40px',
            }}
          >
            <button className="btn btn-link link-button pl-0 ml-0" onClick={this.onKeepRate}>
              Keep Rate
            </button>
            <button className="btn btn-danger bold-button" onClick={this.onEditRate}>
              Edit Rate
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  initAutocomplete = (e) => {
    console.log('ShiftForm::initAutocomplete');

    const autocompleteOptions = {
      componentRestrictions: {
        country: 'us',
      },
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.addressRef.current,
      autocompleteOptions
    );
    this.autocomplete.addListener('place_changed', () => {
      const places = this.autocomplete.getPlace();
      console.log('[Places API]::place_changed', places);
      this.props.onGooglePlaceSelected(
        GoogleSerializer.eventLocationFromAutocompleteResult(places)
      );
    });
  };

  componentDidMount() {
    if (isContractEo(this.props.user.email)) {
      const nextWeek = moment().add(1, 'week');
      const start = nextWeek.clone().add(5, 'days').add(8, 'hours');
      const end = nextWeek.clone().add(5, 'days').add(10, 'hours');
      this.props.onFieldChange('start')({ target: { value: start.format('YYYY-MM-DD HH:mm') } });
      this.props.onFieldChange('end')({ target: { value: end.format('YYYY-MM-DD HH:mm') } });
    }
    if (this.addressRef.current) {
      console.log('ShiftForm::componentDidMount: this.addressRef.current is defined');
      this.initAutocomplete();
    } else {
      console.warn('ShiftForm::componentDidMount: this.addressRef.current is undefined');
    }
  }

  componentDidUpdate() {
    if (!this.autocomplete) {
      console.warn('ShiftForm::componentDidUpdate: Autocomplete not initialized.happen.');
      if (this.addressRef.current) {
        console.log('ShiftForm::componentDidUpdate: this.addressRef.current is defined');
        this.initAutocomplete();
      } else {
        console.warn('ShiftForm::componentDidUpdate: this.addressRef.current is undefined');
      }
    }
  }
}

const mapStateToProps = (state: AppState, ownProps: ShiftFormProps) => {
  const { details, shifts } = state.createEvent;
  const { enums } = state;
  return {
    wasValidated: shifts.wasValidated,
    invalidLocationSelected: shifts.invalidLocationSelected,
    noZipcodeSelected: shifts.noZipcodeSelected,
    isEventCamp: isEventCamp(details.settingDetailId, enums.settingDetails),
    isEventIndustrial: isEventIndustrial(
      details.eventProfessionId,
      enums.professions,
      details.eventSettingId,
      enums.eventSettings
    ),
    sports: enums.sports,
    user: state.session.currentUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onReuseLocationChanged: (ev) => {
    dispatch(reuseLocationChanged(ev.target.checked));
  },
  onReuseOnsiteContactsChanged: (ev) => {
    dispatch(reuseOnsiteContactsChanged(ev.target.checked));
  },
  onModalClear: () => dispatch(clearInvalidMSAMessage()),
  onModalClearNoZipcode: () => dispatch(clearNoZipcodeMessage()),
  onAddReportToContact: (shift) => () => {
    dispatch(addReportToContact(shift));
  },
  onRemoveReportToContact: (shift) => (index) => {
    dispatch(removeReportToContact(shift, index));
  },
  onChangeReportToContact: (shift) => (index, contact) => {
    dispatch(reportToContactChanged(shift, index, contact));
  },
});

export { ShiftForm };

const dateTimePickerOptions = {
  enableTime: true,
  dateFormat: 'Y-m-d H:i',
  altFormat: 'm/d/y h:i K',
  altInput: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftForm);
