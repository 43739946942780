import { get, startsWith } from 'lodash';

import { CredentialSerializer } from 'services/serializers';
import { SERVICE_URL } from 'services/ApiService';

export default class UserSerializer {
  static deserialize(user) {
    const result = {
      id: user.id,
      addressZip: user.address_zip,
      createdAt: user.created_at,
      credentials: [],
      companyName: user.company_name,
      city: user.address_city,
      state: user.address_state,
      street: user.address_street1,
      deCommits: user.de_commits,
      callouts: user.callouts,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      referralCode: user.referral_code,
      name: `${user.first_name} ${user.last_name}`,
      nataNumber: user.nata_id,
      phone: user.phone_no?.replace(/[^0-9]/g, ''),
      role: user.role,
      shiftsWorked: user.shifts_worked,
      type: user.type,
      url: user.url,
      paymentFee: user.payment_fee,
      npiNumber: user.npi_id,
      eventProfessionId: user.profession_id,
      financeEmail: user.finance_email,
      defaultEventSettingId: user.default_event_setting_id,
      defaultEventProfessionId: user.default_event_profession_id,
      expectedYearlyStaffedHours: user.expected_yearly_staffed_hours,
      expectedAnnualHires: user.expected_annual_hires,
      tier: user.tier,
    };

    if (get(user, 'credentials.length') > 0) {
      for (const credential of user.credentials) {
        result['credentials'].push(CredentialSerializer.deserialize(credential));
      }
    }

    if (user.profile_pic) {
      result['profilePic'] = user.profile_pic.includes("s3.amazonaws.com") ?
        user.profile_pic : (
          !startsWith(user.profile_pic, SERVICE_URL)
            ? `${SERVICE_URL}${user.profile_pic}`
            : user.profile_pic
        );
    }

    return result;
  }

  static serialize(user) {
    const phone = user.phone?.length === 10 ? `1${user.phone}` : user.phone;

    const result = {
      id: user.id,
      address_zip: user.addressZip,
      address_street1: user.street,
      address_city: user.city,
      address_state: user.state,
      company_name: user.companyName,
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      phone_no: phone,
      url: user.url,
      referral_code: user.referralCode,
      finance_email: user.financeEmail,
    };

    if (user?.expectedYearlyStaffedHours) {
      result['expected_yearly_staffed_hours'] = user.expectedYearlyStaffedHours;
    }

    if (user?.expectedAnnualHires) {
      result['expected_annual_hires'] = user.expectedAnnualHires;
    }

    if (user?.hearAboutUsSource) {
      result["hear_about_us"] = user.hearAboutUsSource;
      if (user?.hearAboutUsDetails) {
        result["hear_about_us"] += " " + user?.hearAboutUsDetails;
      }
    }

    if (user.hasOwnProperty('defaultEventSettingId')) {
      result["default_event_setting_id"] = user.defaultEventSettingId;
    }

    if (user.hasOwnProperty('defaultEventProfessionId')) {
      result["default_event_profession_id"] = user.defaultEventProfessionId;
    }

    if (user.currentPassword) {
      result['current_password'] = user.currentPassword;
    }

    if (user.password) {
      result['password'] = user.password;
      result['password_confirmation'] = user.passwordConfirmation;
    }

    if (user.profilePic && startsWith(user.profilePic, "data:")) {
      result['profile_pic'] = user.profilePic;
    }

    if (user.type) {
      result['type'] = user.type;
    }

    return result;
  }

  static deserializeHCT(user) {
    const result = {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phone: user.phone_no,
      eventProfessionId: user.profession_id,
      credentials: [],
      shiftsWorked: user.shifts_worked,
      deCommits: user.de_commits,
      callouts: user.callouts,
      name: `${user.first_name} ${user.last_name}`
    };

    if (get(user, 'credentials.length') > 0) {
      for (const credential of user.credentials) {
        result['credentials'].push(CredentialSerializer.deserialize(credential));
      }
    }

    if (user.profile_pic) {
      result['profilePic'] = user.profile_pic.includes("s3.amazonaws.com") ?
          user.profile_pic : (
            !startsWith(user.profile_pic, SERVICE_URL)
              ? `${SERVICE_URL}${user.profile_pic}`
              : user.profile_pic
          );
    }

    return result;
  }
}
