import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  changeApplicantsEventsPage,
  changeApplicantsSearch,
  changeApplicantsSort,
  getApplicantsEvents,
  resetPagination,
} from 'redux/ducks/Dashboard';
import EventList, { scenes } from 'components/V2/EventList/EventList';
import { APPLICANTS_COLUMNS } from 'helpers/dashboard';
import { jobsInStateFromEvent } from 'helpers/dashboard';

class ApplicantsToReview extends Component {
  onChangePage = (data) => {
    const page = data.selected + 1;
    this.props.onChangePage(page);
  };

  reloadData = () => {
    this.props.resetPagination();
    this.props.getApplicantsEvents();
  }

  render() {
    const { events, pagination, search, text, sort } = this.props;
    const key = Object.keys(sort)[0];
    const value = sort[key];
    return (
      <div className="applicants">
        <EventList
          title={text}
          onChangeSearch={this.props.onChangeSearch}
          searchValue={search}
          data={events}
          reloadData={this.reloadData}
          pagination={pagination}
          onChangePage={this.onChangePage}
          column={scenes.APPLICANTS}
          columns={APPLICANTS_COLUMNS}
          emptyMessage="There are no outstanding applications."
          sort={{ key, value }}
          onChangeSort={this.props.onChangeSort}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let events = state.dashboard.applicants.applicants || [];
  events = events.filter(event => jobsInStateFromEvent(event, 'pending').length > 0);

  return {
    events: events,
    search: state.dashboard.applicants.search,
    sort: state.dashboard.applicants.sort,
    pagination: state.dashboard.applicants.pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getApplicantsEvents: () => dispatch(getApplicantsEvents()),
    onChangeSearch: (payload) => dispatch(changeApplicantsSearch(payload)),
    onChangeSort: (key, value) =>
      dispatch(changeApplicantsSort({ key, value })),
    onChangePage: (page) => dispatch(changeApplicantsEventsPage(page)),
    resetPagination: () => dispatch(resetPagination('applicants')),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantsToReview);
