import React, { Component } from 'react';
import UpcomingListItem from './UpcomingListItem';
import classNames from 'classnames';
import Pagination from '../Pagination';
import UpcomingShiftItem from './UpcomingShifts/UpcomingShiftItem/UpcomingShiftItem';
import DraftListItem from './Drafts/DraftListItem';
import FinancesListItem from './FinancesListItem';
import ShiftModificationsItem from 'scenes/Dashboard/V2/Widgets/ShiftModifications/components';
import ShiftsWithNoAppsItem from 'scenes/Dashboard/V2/Widgets/ShiftsWithNoApps/ShiftsWithNoAppsItem/ShiftsWithNoAppsItem';
import ATFeedbackItem from 'scenes/Dashboard/V2/Widgets/ATFeedback/ATFeedbackItem/ATFeedbackItem';
import ApplicantsToReviewItem from 'scenes/Dashboard/V2/Widgets/ApplicantsToReview/ApplicantsToReviewItem';
import SortComponent from 'components/SortComponent';

export const scenes = {
  UPCOMING: 'upcoming',
  FINANCES: 'finances',
  UPCOMING_SHIFTS: 'upcoming_shifts',
  DRAFTS: 'drafts',
  MODIFICATIONS: 'modifications',
  NO_APPS_SHIFTS: 'no_apps_shifts',
  FEEDBACK: 'feedback',
  APPLICANTS: 'applicants',
};

const SHOW_LOCATION_PANEL = ['upcoming_shifts'];

class EventList extends Component {
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.reloadData();
      event.preventDefault();
    }
  };

  showLocationPanel = () => {
    return SHOW_LOCATION_PANEL.includes(this.props.column) && !this.props.isMultipleLocation;
  };

  renderItem = (props) => {
    const mapPropsToRender = {
      [scenes.UPCOMING]: UpcomingListItem,
      [scenes.DRAFTS]: DraftListItem,
      [scenes.FINANCES]: FinancesListItem,
      [scenes.UPCOMING_SHIFTS]: UpcomingShiftItem,
      [scenes.MODIFICATIONS]: ShiftModificationsItem,
      [scenes.NO_APPS_SHIFTS]: ShiftsWithNoAppsItem,
      [scenes.APPLICANTS]: ApplicantsToReviewItem,
      [scenes.FEEDBACK]: ATFeedbackItem,
    };

    const RenderComponent = mapPropsToRender[this.props.column];
    return <RenderComponent key={props.item.id} {...props} />;
  };

  onSearchAction = (event) => {
    this.props.onChangeSearch(event.target.value);
  };

  getSortValue = (columnKey, newKey, value) => {
    if (columnKey === newKey) {
      return value === 'asc' ? 'desc' : 'asc';
    }
    return 'asc';
  };

  onClickSort = (columnKey) => {
    if (!columnKey) {
      return;
    }
    const { sort } = this.props;
    const value = this.getSortValue(columnKey, sort.key, sort.value);
    this.props.onChangeSort(columnKey, value);
  }

  render() {
    const { columns, shiftsLocation, sort } = this.props;

    return (
      <div className="eventListV2">
        <div className="table">
          <div
            className="table-header"
            style={{
              paddingBlock: this.showLocationPanel() ? '20px 5px' : '20px',
            }}
          >
            <div className="table-header-title">
              <h6>View:</h6>
              {this.props.title}
            </div>
            <div className='left-actions'>
              {this.props.hasFilters && (
                <i
                  className="material-icons filters"
                  onClick={this.props.onOpenFilters}
                >
                  tune
                </i>
              )}
              {!this.props.noSearchBar && <div className="search-bar">
                <i className="material-icons">search</i>
                <input
                  type="text"
                  onChange={this.onSearchAction}
                  value={this.props.searchValue}
                  onKeyUp={this.handleKeyPress}
                />
                <button>
                  <i className="material-icons" onClick={this.props.reloadData}>
                    chevron_right
                  </i>
                </button>
              </div>}
            </div>
          </div>
          {this.showLocationPanel() && (
            <div className="table-location-panel">
              <div className="table-location">
                VENUE: <span>{shiftsLocation?.name}</span>
              </div>
              <div className="table-location">
                ADDR: <span>{shiftsLocation?.formattedAddress}</span>
              </div>
            </div>
          )}
          <div className="table-body">
            <div
              className={classNames('table-body-header', {
                'long-table': !!this.props.isMultipleLocation,
              })}
            >
              {Object.entries(columns).map(([key, column]) => (
                <div
                  key={key}
                  className={classNames(`${column.size}`, {
                    'action-padding-left': column.actionPadding,
                    'has-sort': !!column.sortBy,
                    'ats-padding': column.atsPadding,
                    [`custom-width-${column.accessor}`]:
                      !!this.props.isMultipleLocation && column.hasCustomWidth,
                  })}
                  onClick={() => this.onClickSort(column.sortBy)}
                >
                  {column.name}
                  {column.sortBy && (
                    <SortComponent
                      title={column.sortBy === sort?.key ? sort?.value : ''}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="table-body-rows">
              {this.props.data.length === 0 && (
                <div className="no-data">
                  <br />
                  {this.props.emptyMessage}
                </div>
              )}
              {this.props.data.map((item, idx) => {
                return this.renderItem({ ...this.props, item, columns, idx });
              })}
            </div>
          </div>
          <br />
          <Pagination
            pagination={this.props.pagination}
            handlePageClick={this.props.onChangePage}
          />
        </div>
      </div>
    );
  }
}

export default EventList;
