export const stateRates = [
  { state: 'AK', rate: "$44", },
  { state: 'AL', rate: "$40", },
  { state: 'AR', rate: "$40", },
  { state: 'AZ', rate: "$44", },
  { state: 'CA', rate: "$52", },
  { state: 'CO', rate: "$39", },
  { state: 'CT', rate: "$51", },
  { state: 'DC', rate: "$55", },
  { state: 'DE', rate: "$45", },
  { state: 'FL', rate: "$41", },
  { state: 'GA', rate: "$40", },
  { state: 'HI', rate: "$40", },
  { state: 'IA', rate: "$40", },
  { state: 'ID', rate: "$48", },
  { state: 'IL', rate: "$50", },
  { state: 'IN', rate: "$44", },
  { state: 'KS', rate: "$37", },
  { state: 'KY', rate: "$42", },
  { state: 'LA', rate: "$38", },
  { state: 'MA', rate: "$55", },
  { state: 'MD', rate: "$47", },
  { state: 'ME', rate: "$46", },
  { state: 'MI', rate: "$42", },
  { state: 'MN', rate: "$46", },
  { state: 'MO', rate: "$39", },
  { state: 'MS', rate: "$36", },
  { state: 'MT', rate: "$44", },
  { state: 'NC', rate: "$42", },
  { state: 'ND', rate: "$50", },
  { state: 'NE', rate: "$45", },
  { state: 'NH', rate: "$49", },
  { state: 'NJ', rate: "$46", },
  { state: 'NM', rate: "$50", },
  { state: 'NV', rate: "$41", },
  { state: 'NY', rate: "$55", },
  { state: 'OH', rate: "$43", },
  { state: 'OK', rate: "$37", },
  { state: 'OR', rate: "$44", },
  { state: 'PA', rate: "$48", },
  { state: 'RI', rate: "$54", },
  { state: 'SC', rate: "$44", },
  { state: 'SD', rate: "$44", },
  { state: 'TN', rate: "$42", },
  { state: 'TX', rate: "$40", },
  { state: 'UT', rate: "$42", },
  { state: 'VA', rate: "$50", },
  { state: 'VT', rate: "$40", },
  { state: 'WA', rate: "$46", },
  { state: 'WI', rate: "$47", },
  { state: 'WV', rate: "$44", },
  { state: 'WY', rate: "$44", },
]