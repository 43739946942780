import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { FOUR, INNERCIRCLE, OUTERCIRCLE } from './SpinnerSVG'

const LoadingSpinner = ({isOpen}) => {
  return (
    <Modal isOpen={isOpen} modalClassName="loading-modal">
      <div className="spinner-container">
        <FOUR className="four"/>
        <INNERCIRCLE className="four spin-clockwise"/>
        <OUTERCIRCLE className="four spin-trigonometric"/>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({showLoadingSpinner}) => ({ isOpen: showLoadingSpinner });

export default connect(mapStateToProps)(LoadingSpinner);
