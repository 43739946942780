import { get, sortBy } from 'lodash';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';

export const EMPTY_DATE = '-- / -- / --';

export const getAlerts = ({ isShift } = { isShift: false }) => [
  {
    key: 'boost',
    name: 'NO APPLICATIONS',
  },
  {
    key: 'review',
    name: 'APPLICATIONS',
  },
  {
    key: 'filled',
    name: `${isShift ? 'SHIFT' : 'ALL SHIFTS'} FILLED / NO ALERTS`,
  },
  {
    key: 'new',
    name: 'NEW POSTING / NO ACTION',
  },
  {
    key: 'waitlist',
    name: `${isShift ? 'SHIFT' : 'ALL SHIFTS'} FILLED / WAITLIST`,
  },
  {
    key: 'boosted',
    name: isShift ? 'NO APPS' : 'NO APPLICATIONS',
  },
  {
    key: 'confirmation',
    name: 'PENDING AT CONFIRMATION',
  },
];

export const MODIFICATIONS_COLUMNS = {
  startDate: {
    name: 'START DATE',
    size: 'col-1',
    accessor: 'startDate',
    sortBy: 'date',
  },
  jobName: {
    name: 'JOB NAME',
    size: 'col-3',
    accessor: 'jobName',
    sortBy: 'title',
  },
  at: {
    name: 'AT',
    size: 'col-2',
    accessor: 'at',
    sortBy: 'at_name',
  },
  modifiedTime: {
    name: 'MODIFIED TIME',
    size: 'col-2',
    accessor: 'modifiedTime',
  },
  modifiedPay: {
    name: 'MODIFIED PAY',
    size: 'col-2',
    accessor: 'modifiedPay',
  },
  actions: {
    name: 'ACTIONS',
    size: 'col-2',
    accessor: 'actions',
  },
};

export const NO_APPS_SHIFTS_COLUMNS = {
  jobName: {
    name: 'JOB NAME',
    size: 'col-3',
    accessor: 'jobName',
    sortBy: 'title',
  },
  startTime: {
    name: 'START TIME',
    size: 'col-2',
    accessor: 'startTime',
    sortBy: 'start_time',
  },

  location: {
    name: 'LOCATION',
    size: 'col-3',
    accessor: 'location',
    sortBy: 'location',
  },
  rate: {
    name: 'RATE',
    size: 'col-2',
    accessor: 'rate',
    sortBy: 'rate',
  },
  boostRate: {
    name: 'BOOST RATE',
    size: 'col-2',
    accessor: 'boostRate',
  },
};

export const APPLICANTS_COLUMNS = {
  jobName: {
    name: 'JOB NAME',
    size: 'col-3',
    accessor: 'jobName',
    sortBy: 'title',
  },
  startDate: {
    name: 'START DATE',
    size: 'col-1',
    accessor: 'startDate',
    sortBy: 'date',
  },
  location: {
    name: 'LOCATION',
    size: 'col-3',
    accessor: 'location',
    sortBy: 'location',
  },
  filled: {
    name: 'FILLED',
    size: 'col-1',
    accessor: 'filled',
  },
  apps: {
    name: 'APPS',
    size: 'col-1',
    accessor: 'apps',
  },
  viewApplicants: {
    name: 'VIEW APPLICANTS',
    size: 'col-3',
    accessor: 'viewApplicants',
  },
};

export const FEEDBACK_COLUMNS = {
  atName: {
    name: 'AT NAME',
    size: 'col-2',
    accessor: 'atName',
    sortBy: 'at_name',
  },
  jobWorked: {
    name: 'JOB WORKED',
    size: 'col-3',
    accessor: 'jobWorked',
    sortBy: 'title',
  },
  location: {
    name: 'LOCATION',
    size: 'col-3',
    accessor: 'location',
    sortBy: 'location',
  },
  dateWorked: {
    name: 'DATE WORKED',
    size: 'col-2',
    accessor: 'dateWorked',
    sortBy: 'date',
  },
  actions: {
    name: 'ACTIONS',
    size: 'col-2',
    accessor: 'actions',
  },
};

export const FINANCES_COLUMNS = {
  startDate: {
    name: 'START DATE',
    size: 'col-1',
    accessor: 'startDate',
    sortBy: 'date',
  },
  jobName: {
    name: 'JOB NAME',
    size: 'col-3',
    accessor: 'jobName',
    sortBy: 'title',
  },
  ATs: {
    name: 'ATs',
    size: 'col-1',
    accessor: 'rate',
    atsPadding: true,
  },
  totalHired: {
    name: 'TOTAL HIRED',
    size: 'col-1',
    accessor: 'cityState',
  },
  totalPayment: {
    name: 'TOTAL PAYMENT',
    size: 'col-2',
    accessor: 'filled',
    sortBy: 'total_payment',
  },
  lastPayment: {
    name: 'LAST PAYMENT',
    size: 'col-2',
    accessor: 'filled',
    sortBy: 'last_payment',
  },
  actions: {
    name: 'ACTIONS',
    size: 'col-2',
    accessor: 'actions',
  },
};

export const UPCOMING_COLUMNS = {
  startDate: {
    name: 'START DATE',
    size: 'col-1',
    accessor: 'startDate',
    sortBy: 'date',
  },
  jobName: {
    name: 'JOB NAME/VIEW SHIFTS',
    size: 'col-4',
    accessor: 'jobName',
    sortBy: 'title',
  },
  filled: {
    name: 'FILLED',
    size: 'col-1',
    accessor: 'filled',
  },
  alert: {
    name: 'ALERT',
    size: 'col-3',
    accessor: 'alert',
  },
  actions: {
    name: 'ACTIONS',
    size: 'col-3',
    accessor: 'actions',
  },
};

export const UPCOMING_SHIFTS_COLUMNS = {
  date: {
    name: 'DATE',
    size: 'col-1',
    accessor: 'date',
    sortBy: 'date',
  },
  dateTime: {
    name: 'TIME',
    size: 'col-2',
    accessor: 'dateTime',
    hasCustomWidth: true,
  },
  location: {
    name: 'LOCATION',
    size: 'col-2',
    accessor: 'location',
    sortBy: 'location',
  },
  ATs: {
    name: 'ATs',
    size: 'col-1',
    accessor: 'ATS',
    atsPadding: true,
  },
  filled: {
    name: 'FILLED',
    size: 'col-1',
    accessor: 'filled',
  },
  alert: {
    name: 'ALERT',
    size: 'col-2',
    accessor: 'alert',
    hasCustomWidth: true,
  },
  rate: {
    name: 'RATE',
    size: 'col-1',
    accessor: 'rate',
    sortBy: 'rate',
  },
  actions: {
    name: 'ACTIONS',
    size: 'col-2',
    accessor: 'actions',
    actionPadding: true,
  },
};

export const DRAFTS_COLUMNS = {
  startDate: {
    name: 'START DATE',
    size: 'col-1',
    accessor: 'startDate',
    sortBy: 'date',
  },
  jobName: {
    name: 'JOB NAME',
    size: 'col-4',
    accessor: 'jobName',
    sortBy: 'title',
  },
  status: {
    name: 'STATUS',
    size: 'col-3',
    accessor: 'status',
  },
  actions: {
    name: 'ACTIONS',
    size: 'col-4',
  },
};

export const SHOW_TOOLTIP_COLUMNS = {
  shifts: ['jobName', 'startTime', 'location', 'rate'],
  applicants: ['jobName', 'startDate', 'location', 'filled', 'apps'],
  modifications: ['startDate', 'jobName', 'at', 'modifiedTime', 'modifiedPay'],
  feedback: ['jobWorked', 'location', 'dateWorked'],
  finances: [
    'startDate',
    'jobName',
    'totalHired',
    'totalPayment',
    'lastPayment',
  ],
  upcoming: ['startDate', 'jobName', 'filled'],
  upcomingShifts: ['location'],
  drafts: ['startDate', 'jobName'],
  experienceModal: ['atName'],
  jobsForShift: ['atName', 'date', 'time', 'rate', 'expirationDate'],
};

export const createOverflowChecker = (tableName, columnRefs, setState) => {
  return () => {
    const isOverflowing = {};
    SHOW_TOOLTIP_COLUMNS[tableName].forEach((column) => {
      isOverflowing[column] =
        columnRefs[column].current.scrollWidth >
        columnRefs[column].current.clientWidth;
    });
    setState({ isOverflowing });
  };
};

export function formatAddressForUpcoming(name, { city, state }): string {
  return `${name} / ${city}, ${state}`;
}

export const jobsInStateFromEvent = (event, state) => {
  const shifts = Array.isArray(event.shifts)
    ? event.shifts
    : Object.values(get(event, 'shifts.byId', {}));
  if (!shifts) return [];

  let result = shifts.reduce((acc, shift) => {
    const jobs = Array.isArray(shift.jobs)
      ? shift.jobs
      : Object.values(get(shift, 'jobs.byId', {}));
    let jobsInState = jobs.filter((job) => job.currentState === state);
    jobsInState = jobsInState.map((job) => {
      return { ...job, shiftLocation: shift.eventLocation };
    });
    return acc.concat(jobsInState);
  }, []);
  result = sortBy(result, 'startTime');

  return result;
};
export const activeShifts = (shifts) => {
  return shifts.filter((shift) => {
    const tz =
      zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) ||
      moment.tz.guess();
    return moment.tz(shift.startTime, tz).isAfter(moment());
  });
};

export const shiftStatistics = (jobs) => {
  let statistics = {
    capacityFilled: 0,
    shiftRequests: 0,
    pendingConfirmation: 0,
  };

  jobs.forEach((job) => {
    if (
      job.currentState === 'checked_in' ||
      job.currentState === 'confirmed' ||
      job.currentState === 'paid'
    ) {
      statistics.capacityFilled += 1;
    }

    if (
      job.currentState === 'pending' &&
      moment(job.startTime).isAfter(moment())
    ) {
      statistics.shiftRequests += 1;
    }

    if (job.currentState === 'eo_accepted_at') {
      statistics.pendingConfirmation += 1;
    }
  });
  return statistics;
};

export const formatCurrency = ({ currency, dollarSign }) => {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (dollarSign) {
    return `$${numberFormatter.format(currency)}`;
  }

  return numberFormatter.format(currency);
};
