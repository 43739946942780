import * as v1ShiftActions from '../../CreateEvent/Shifts/actions';
import * as postActions from '../actions';
import * as locationActions from './actions';
import * as eapActions from '../EAP/actions';

import { newEventLocation } from 'redux/ducks/helpers';
import { get } from 'lodash';
import { addErrorsToLocation } from './validators';
import { DEFAULT_DETAILS } from 'services/serializers/v2/DetailsSerializer';
import LocationSerializer from 'services/serializers/v2/LocationSerializer';
import { AVAILABLE_EQUIPMENTS } from 'helpers/post';

// Reducer Functions
// ========================================================

export const initLocationsReducer = (state, payload) => {
  if (!Array.isArray(payload.shifts) || payload.shifts.length === 0) return state;

  return LocationSerializer.deserializeCamelCase({
    shifts: payload.shifts,
    location_documents: payload.location_documents,
  }).map(addErrorsToLocation);
};

const fieldChangeReducer = (state, payload: { idx: number, name: string, value: string }) => {
  return state
    .map((location, index) =>
      index === payload.idx ? { ...location, [payload.name]: payload.value } : location
    )
    .map(addErrorsToLocation);
};

const reloadFormReducer = (state, payload) => {
  return (payload?.locations ? [...payload.locations] : state).map(addErrorsToLocation);
};

const placeDetailsSuccessReducer = (state, { index, result }) => {
  const updatedLocations = state.map((location, idx) => {
    return idx !== index
      ? location
      : {
        ...location,
        ...result,
        address: {
          ...location.address,
          ...result.address,
          id: null,
          isSearching: false,
        },
      };
  });

  return updatedLocations.map(addErrorsToLocation);
};

const invalidMSAReducer = (state, { index }) => {
  const stateWithInvalidMSAFlag = {
    ...state,
    invalidLocationSelected: true,
  };
  return placeDetailsSuccessReducer(stateWithInvalidMSAFlag, { index, result: newEventLocation() });
};

const noZipcodeReducer = (state, { index }) => {
  const stateWithNoZipcodeFlag = {
    ...state,
    noZipcodeSelected: true,
  };
  return placeDetailsSuccessReducer(stateWithNoZipcodeFlag, { index, result: newEventLocation() });
};

const newReportToContactReducer = (state, payload) => {
  const { locationIndex } = payload;
  const contacts = get(state, `[${locationIndex}].reportToContacts`, []) || [];
  return state
    .map((location, idx) =>
      idx !== locationIndex
        ? location
        : {
          ...location,
          reportToContacts: [...contacts, new ReportToContact()],
        }
    )
    .map(addErrorsToLocation);
};

const removeReportFromContactReducer = (state, payload) => {
  const { contactIndex, locationIndex } = payload;
  const contacts = get(state, `[${locationIndex}].reportToContacts`, []) || [];
  return state
    .map((location, idx) =>
      idx !== locationIndex
        ? location
        : {
          ...location,
          reportToContacts: contacts.filter((val, i) => i !== contactIndex),
        }
    )
    .map(addErrorsToLocation);
};

const changeReportFromContactReducer = (state, payload) => {
  const { idx, contact, locationIndex } = payload;
  const contacts = get(state, `[${locationIndex}].reportToContacts`, []) || [];
  const updatedContacts = contacts.map((ct, index) => (index === idx ? contact : ct));
  return state
    .map((location, idx) =>
      idx !== locationIndex
        ? location
        : {
          ...location,
          reportToContacts: updatedContacts,
        }
    )
    .map(addErrorsToLocation);
};

const validateFormReducer = (state, payload) => {
  const shiftsWithErrorMessages = state.map(addErrorsToLocation);
  return shiftsWithErrorMessages;
};

const locationsReducer = (state, payload) => {
  return payload.locations
    ?.map((location, idx) => (!location.id ? state[idx] || location : location))
    .map(addErrorsToLocation);
};

const addMultipleLocationsReducer = (state, payload) => {
  const numberOfExistingLocations = state.length;
  const { locationsNumber } = payload;

  const locationsToAdd = locationsNumber - numberOfExistingLocations;
  if (locationsToAdd <= 0) return state;

  const newLocations = Array.from(Array(locationsToAdd).keys()).map((idx) =>
    newLocation(idx + numberOfExistingLocations)
  );

  return [...state, ...newLocations].map(addErrorsToLocation);
};

const suppliesFieldChangeReducer = (state, payload) => {
  const { locationIndex, name, value } = payload;
  const location = state[locationIndex];
  const updatedLocation = {
    ...location,
    supplies: {
      ...location.supplies,
      [name]: value,
    },
  };

  return state
    .map((location, idx) => (idx !== locationIndex ? location : updatedLocation))
    .map(addErrorsToLocation);
};

const locationDeletedReducer = (state, payload) => {
  const { locationIndex } = payload;
  const updatedLocations = state.filter((location) => location.idx !== locationIndex);

  if (updatedLocations.length === 0) return [newLocation(0)];

  return updatedLocations.map(addErrorsToLocation);
};

const deleteDocumentReducer = (state, { documentId, locationId }) => {
  return state.map((location, index) => {
    if (index !== locationId) return location;
    return {
      ...location,
      existingDocuments: location.existingDocuments.filter(
        (document) => document.id !== documentId
      ),
    };
  });
};

const synchronizeEAPSupplies = (state, payload) =>
  state.map((location) =>
    location.id !== payload.eventLocation.id
      ? location
      : {
        ...location,
        supplies: {
          ...location.supplies,
          equipments: [
            ...location.supplies.equipments.filter(
              (supply) => !AVAILABLE_EQUIPMENTS.includes(supply)
            ),
            ...AVAILABLE_EQUIPMENTS.filter((eq) =>
              payload.emergencyEquipment.map(({ name }) => name).includes(eq)
            ),
          ],
        },
      }
  );

// Reducer
// ========================================================

export class ReportToContact {
  email = '';
  name = '';
  phoneNumber = '';
}

export const newLocation = (idx: number) => ({
  ...new newEventLocation(),
  idx: idx,
  instructions: '',
  countValue: 1,
  countType: 'noFields',
  reportToContacts: [new ReportToContact()],
  errorMessages: {},
  supplies: DEFAULT_DETAILS,
  scheduleType: '',
  payRate: 0,
  rateTypeId: '0',
  documents: [],
  existingDocuments: [],
});

const DEFAULT_STATE = [newLocation(0)];

export type Location = EventLocation & {
  idx: number,
  instructions: string,
  countValue: number,
  countType: string,
  errorMessages: Object,
  supplies: Details,
  scheduleType: string,
  reportToContacts: Array<ReportToContact>,
  payRate: string,
  rateTypeId: string,
  documents: any[],
  existingDocuments: any[],
};

export type LocationType = Array<Location>;

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case locationActions.FIELD_CHANGE:
      return fieldChangeReducer(state, action.payload);
    case locationActions.ADD_NEW_REPORT_TO_CONTACTS:
      return newReportToContactReducer(state, action.payload);
    case locationActions.REMOVE_REPORT_FROM_CONTACTS:
      return removeReportFromContactReducer(state, action.payload);
    case locationActions.CHANGE_REPORT_FROM_CONTACTS:
      return changeReportFromContactReducer(state, action.payload);
    case locationActions.ADD_NEW_LOCATION:
      return [...state, newLocation(state.length)].map(addErrorsToLocation);
    case locationActions.ADD_MULTIPLE_LOCATIONS:
      return addMultipleLocationsReducer(state, action.payload);
    case locationActions.SUPPLIES_FIELD_CHANGE:
      return suppliesFieldChangeReducer(state, action.payload);
    case locationActions.DELETE_LOCATION:
      return locationDeletedReducer(state, action.payload);

    case v1ShiftActions.PLACE_DETAILS_SUCCESS:
      return placeDetailsSuccessReducer(state, action.payload);
    case v1ShiftActions.INVALID_MSA_SELECTED:
      return invalidMSAReducer(state, action.payload);
    case v1ShiftActions.NO_ZIPCODE_SELECTED:
      return noZipcodeReducer(state, action.payload);

    case postActions.V2_RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case postActions.V2_VALIDATE_FORM:
      return validateFormReducer(state, action.payload);
    case postActions.V2_CLEAR_FORM:
      return DEFAULT_STATE;
    case postActions.SAVE_AS_DRAFT_SUCCESS:
    case postActions.SAVE_PROFILE_SUCCESS:
      return locationsReducer(state, action.payload);
    case postActions.INITIALIZE_POST:
    case postActions.DUPLICATE_POST:
      return initLocationsReducer(state, action.payload);
    case locationActions.DELETE_SHIFT_DOCUMENT:
      return deleteDocumentReducer(state, action.payload);
    case eapActions.UPDATE_EAP_SUCCESS:
      return synchronizeEAPSupplies(state, action.payload);
    default:
      return state;
  }
}
