import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { SHIFT_CANCELLATION_REASONS, SECONDARY_REASONS, optionsForEnum } from 'helpers/enums';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';

const DeleteModal = ({ onCancel, item, user, isOpen, isEvent, isLocation, ...props }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    cancelReason: '',
    secondaryReason: '',
    otherReason: '',
  });
  const [cancelReason, setCancelReason] = useState('');
  const [secondaryReason, setSecondaryReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  const onCancelModal = () => {
    if (onCancel) onCancel();
    toggle();
  };

  const toggle = () => {
    if (props.toggle) {
      props.toggle();
      return;
    }
    setOpen(!open);
  };

  const onDelete = (reason) => {
    if (!!isEvent) props.onDelete({ ...item, reason });
    else props.onDelete(item.idx, reason);
  };

  const onDeleteLocation = (reason) => {
    if (!!props.fromEdit) {
      props.onDeleteLocation({
        locationId: props.location.id,
        locationIndex: props.location.idx,
        reason,
      });
    }
    props.onDeleteLocation(props.locationId, props.event, reason);
  };

  const sendWeatherCioEvent = () => {
    let confirmedAts = 0;
    if (!!isEvent) {
      Object.values(item.shifts.byId).forEach((shift) => {
        Object.values(shift.jobs.byId).forEach((job) => {
          if (job.currentState === 'confirmed') {
            confirmedAts++;
          }
        });
      });
      window._cio.track('weather_event_cancellations', {
        eo_id: user.id,
        eo_email: user.email,
        eo_first_name: user.firstName,
        eo_last_name: user.lastName,
        organization_name: user.companyName,
        event_name: item.title,
        event_start_date_time: moment(item.nextStartTime).format(),
        at_confirmed_count: confirmedAts,
      });
    } else {
      Object.values(item.jobs.byId).forEach((job) => {
        if (job.currentState === 'confirmed') {
          confirmedAts++;
        }
      });
      window._cio.track('weather_shift_cancellations', {
        eo_id: user.id,
        eo_email: user.email,
        eo_first_name: user.firstName,
        eo_last_name: user.lastName,
        organization_name: user.companyName,
        event_name: props.eventName,
        event_start_date_time: moment(item.startTime).format(),
        at_confirmed_count: confirmedAts,
      });
    }
  };

  const sendCancellationCioEvent = () => {
    window._cio.track('event_cancellation', {
      eo_id: user.id,
      eo_tier: user.tier,
      eo_email: user.email,
      organization_name: user.companyName,
      event_name: item.title,
      event_start_date_time: moment(item.nextStartTime).format(),
      cancellation_date: moment().format(),
      cancellation_reason: cancelReason,
      secondary_cancellation_reason: secondaryReason,
      other_cancellation_reason: otherReason,
    });
  };

  const onClick = () => {
    if (isEmpty(cancelReason)) {
      setError({ ...error, cancelReason: 'This field is required' });
      return;
    }

    if (
      ['Shift Canceled', 'Job Canceled', 'Shifts Canceled', 'Position Filled'].includes(
        cancelReason
      ) &&
      isEmpty(secondaryReason)
    ) {
      setError({ ...error, secondaryReason: 'This field is required' });
      return;
    }

    if (['Other'].includes(secondaryReason) && isEmpty(otherReason)) {
      setError({ ...error, otherReason: 'This field is required' });
      return;
    }

    if (!!isLocation) {
      onDeleteLocation([cancelReason, secondaryReason, otherReason].filter(Boolean).join(` - `));
      toggle();
      return;
    }

    if (secondaryReason === 'Weather' && window._cio) sendWeatherCioEvent();
    if (!!isEvent && window._cio) sendCancellationCioEvent();

    onDelete([cancelReason, secondaryReason, otherReason].filter(Boolean).join(` - `));
    toggle();
  };

  const onChange = (e) => {
    setCancelReason(e.target.value);
    setSecondaryReason('');
    setOtherReason('');
    setError({
      cancelReason: '',
      secondaryReason: '',
      otherReason: '',
    });
  };

  const onChangeSecondaryReason = (e) => {
    setSecondaryReason(e.target.value);
    setError({
      cancelReason: '',
      secondaryReason: '',
      otherReason: '',
    });
  };

  const onChangeOtherReason = (e) => {
    setOtherReason(e.target.value);
    setError({
      cancelReason: '',
      secondaryReason: '',
      otherReason: '',
    });
  };

  const moreThan18HoursLeft = () => {
    const tz = zipcode_to_timezone.lookup(item.zipCode) || moment.tz.guess();
    const diffInHours = !!isEvent
      ? moment(item.nextStartTime).diff(moment(), 'hours')
      : moment.tz(item.startTime, tz).diff(moment(), 'hours');
    return diffInHours >= 18;
  };

  const cancellationReasons = !!isEvent
    ? SHIFT_CANCELLATION_REASONS.filter(
        (reason) => reason.id !== 'Shift Canceled' && reason.id !== 'Shifts Canceled'
      )
    : !!isLocation
    ? SHIFT_CANCELLATION_REASONS.filter(
        (reason) => reason.id !== 'Job Canceled' && reason.id !== 'Shift Canceled'
      )
    : SHIFT_CANCELLATION_REASONS.filter(
        (reason) => reason.id !== 'Job Canceled' && reason.id !== 'Shifts Canceled'
      );

  const secondaryReasons = (cancelReason) => {
    if (
      cancelReason === 'Shift Canceled' ||
      cancelReason === 'Job Canceled' ||
      cancelReason === 'Shifts Canceled'
    ) {
      return moreThan18HoursLeft()
        ? SECONDARY_REASONS[cancelReason].filter((reason) => reason.id !== 'Weather')
        : SECONDARY_REASONS[cancelReason];
    } else return SECONDARY_REASONS[cancelReason];
  };

  const isModalOpen = typeof isOpen === 'boolean' ? isOpen : open;

  const deleteDisabled =
    isEmpty(cancelReason) ||
    (['Shift Canceled', 'Position Filled', 'Job Canceled', 'Shifts Canceled'].includes(
      cancelReason
    ) &&
      isEmpty(secondaryReason)) ||
    (['Other'].includes(secondaryReason) && isEmpty(otherReason));

  return (
    <div>
      {props.children && (
        <button className="modal-button plain-button" onClick={toggle}>
          {props.children}
        </button>
      )}
      <div className="v2-confirmation-modal" style={{ position: 'absolute' }}>
        <Modal isOpen={isModalOpen} toggle={toggle} className="v2-confirmation-modal-content">
          <ModalHeader className="header" toggle={toggle}>
            {props.title || 'Are you sure?'}
          </ModalHeader>
          <ModalBody>
            <p>{props.body}</p>
            <div className="form-group pl-0 mt-4">
              <label>
                Reason<span className="text-danger">*</span>
              </label>
              <select
                className={`form-control rounded-select custom-select ${
                  cancelReason ? '' : 'text-secondary'
                }`}
                name="reason"
                value={cancelReason}
                onChange={onChange}
                required
              >
                {optionsForEnum(cancellationReasons, 'Select a reason')}
              </select>
              {error.cancelReason && (
                <div className="text-danger mt-1 w-100">{error.cancelReason}</div>
              )}
              {['Shift Canceled', 'Job Canceled', 'Position Filled', 'Shifts Canceled'].includes(
                cancelReason
              ) && (
                <div className="form-group pl-0 mt-4">
                  <label>
                    Secondary Reason<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control rounded-select custom-select"
                    name="secondaryReason"
                    value={secondaryReason}
                    onChange={onChangeSecondaryReason}
                    required
                  >
                    {optionsForEnum(secondaryReasons(cancelReason), 'Select a secondary reason')}
                  </select>
                  {secondaryReason === 'Other' && (
                    <div className="mt-3">
                      <input
                        type="text"
                        className="form-control"
                        name="otherReason"
                        value={otherReason}
                        onChange={onChangeOtherReason}
                        required
                      />
                    </div>
                  )}
                  {error.otherReason && (
                    <div className="text-danger mt-1 w-100">{error.otherReason}</div>
                  )}
                </div>
              )}
              {error.secondaryReason && (
                <div className="text-danger mt-1 w-100">{error.secondaryReason}</div>
              )}
              {cancelReason === 'Incorrectly Posted' && (
                <div>
                  <p className="mt-2">
                    Instead of deleting this {!!isEvent ? 'job' : !!isLocation ? 'location' : 'shift'}, learn how to edit it{' '}
                    <a
                      href="https://support.go4.io/hc/en-us/articles/13179441878285-How-to-Edit-an-Event-Job"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>
              )}
              {cancelReason === 'No Applicants' && (
                <div>
                  <p className="mt-2">
                    For future job postings, we suggest posting at minimum 21 days before the start
                    date and at minimum use the state’s average pay rate.
                  </p>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="actions">
            <button className="btn confirm-btn" onClick={onCancelModal}>
              Go Back
            </button>
            <button
              onClick={onClick}
              className={classnames('btn btn-success', {
                disabled: deleteDisabled,
              })}
            >
              Delete
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteModal;
