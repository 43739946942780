import React from 'react';
import EditShiftModal from '../../EditShiftModal';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import { AddButton, DeleteButton } from 'components/V2/Buttons';
import { getTimezone, isEndTimeAfterNow } from 'helpers/post';
import DeleteModal from 'components/V2/ConfirmationModal/DeleteModal';
import { connect } from 'react-redux';
import moment from 'moment';
import { MILITARY_FORMAT } from 'helpers/datetime';

const ShiftActions = (props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const { shift, shifts, editMode, idx } = props;

  const canEdit = (shift) => {
    const tz = getTimezone(shift);
    return isEndTimeAfterNow(moment(shift.endTime, MILITARY_FORMAT).format(), tz);
  };

  const onEdit = () => {
    props.history.replace(`${props.baseUrl}/schedule/one-time#${shift.idx + 1}`);
  };

  return (
    <div key={`shift-actions-${idx}`} className="shift-actions">
      <AddButton show={!editMode} onClick={onEdit}>
        Edit
      </AddButton>
      {editMode && canEdit(shift) && (
        <EditShiftModal key={shift.id} shift={shift} shiftCount={shifts.length} {...props}>
          Edit
        </EditShiftModal>
      )}
      {((editMode && canEdit(shift)) || !editMode) && props.canDelete() && (
        <i className="fas separator">|</i>
      )}
      <DeleteButton
        onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        show={props.canDelete()}
      >
        Delete
      </DeleteButton>
      {editMode ? (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          title="Delete shift"
          body={`Deleting this shift on ${moment(shift.startDate, MILITARY_FORMAT).format(
            'MM/DD/YYYY'
          )} will remove athletic trainers who applied or confirmed to work.`}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          onDelete={props.onDeleteShift}
          item={shift}
          user={props.user}
          eventName={props.eventName}
        />
      ) : (
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          title="Delete shift"
          body={`Are you sure you want to delete this shift ${moment(
            shift.startDate,
            MILITARY_FORMAT
          ).format('MM/DD/YYYY')}?`}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          onSave={() => {
            props.onDeleteShift(shift.idx);
            setIsDeleteModalOpen(!isDeleteModalOpen);
          }}
          confirmText="Delete"
          cancelText="Go Back"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
  eventName: state.post.profile.title,
});

export default connect(mapStateToProps)(ShiftActions);
