import React, { Component } from 'react';
import {
  addShift,
  changeShift,
  changeShiftField,
  removeShift,
  repeatShifts,
  type ShiftsType,
} from 'redux/ducks/Post/Shifts/actions';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import ActionBar from '../../ActionBar';
import { validateForm } from 'redux/ducks/Post';
import { saveAsDraft } from 'redux/ducks/Post/actions';
import moment from 'moment-timezone';
import ShiftGenerator, { SCHEDULE_FIELD_LABELS } from '../../Shift/ShiftGenerator';
import OneTimeShift from '../../Shift/OneTimeShift';
import { AddButton, DeleteButton } from 'components/V2/Buttons';
import {
  scheduleTypes,
  shiftsByLocation,
  trackMixpanelPageView,
  isEndTimeAfterNow,
  getTimezone,
} from 'helpers/post';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import { MILITARY_FORMAT } from 'helpers/datetime';

type Props = {
  shifts: ShiftsType,
};

class RecurringSchedule extends Component<Props> {
  state = {
    weekdays: [],
    schedule: {},
    capacity: 1,
    errorMessages: {},
    wasValidated: false,
    openDeleteModal: false,
  };

  componentDidMount() {
    if (!this.props.editMode)
      trackMixpanelPageView('create-event/schedule/recurring', this.props.user);
  }

  setWeekdays = (weekdays) =>
    this.setState((prev) => ({
      ...prev,
      weekdays,
      errorMessages: this.validateAll({ ...prev, weekdays }),
    }));
  setSchedule = (schedule) =>
    this.setState((prev) => ({
      ...prev,
      schedule: { ...prev.schedule, ...schedule },
      errorMessages: this.validateAll({ ...prev, schedule: { ...prev.schedule, ...schedule } }),
    }));
  setCapacity = (capacity) =>
    this.setState((prev) => ({
      ...prev,
      capacity,
      errorMessages: this.validateAll({ ...prev, capacity }),
    }));
  setDeleteModal = () =>
    this.setState((prev) => ({
      ...prev,
      openDeleteModal: !this.state.openDeleteModal,
    }));

  backTo = () => {
    const { baseUrl, shifts, editMode } = this.props;

    if (editMode && get(shifts, '[0].id', null)) return `${baseUrl}/location`;

    return `${baseUrl}/schedule/type`;
  };

  validateAll = (newState) => {
    const requiredFields = ['startDate', 'endDate', 'start', 'end'];
    const errors = {};
    const { schedule, weekdays, capacity } = newState;

    requiredFields.forEach((field) => {
      if (isEmpty(schedule[field])) errors[field] = `${SCHEDULE_FIELD_LABELS[field]} is required`;
    });

    const startTime = moment(schedule.startDate, MILITARY_FORMAT);
    const endTime = moment(schedule.endDate, MILITARY_FORMAT);

    if (startTime.isBefore(moment().format('MM-DD-YYYY')))
      errors['startDate'] = 'Start Date must be in future';
    if (startTime.isAfter(endTime)) {
      errors['startDate'] = 'Start Date must be before end time';
      errors['endDate'] = 'End Date must be after start time';
    }
    if (weekdays.length < 1) errors['weekdays'] = 'At least one recurring day is required';
    if (Number(capacity) < 1) errors['capacity'] = 'Must have at least 1 athletic trainer';

    return errors;
  };

  pageIsValid = () => {
    const { errorMessages } = this.state;

    const { isDraft } = this.props.post.details;
    if (!isDraft && !this.props.shifts.length) return true;

    const shifts = isDraft
      ? this.props.shifts
      : this.props.shifts.filter(({ endTime }) =>
          isEndTimeAfterNow(moment(endTime, MILITARY_FORMAT).format())
        );

    if (shifts.length > 0)
      return shifts.every((shift) => {
        return (
          !shift.errorMessages['startDate'] &&
          !shift.errorMessages['start'] &&
          !shift.errorMessages['end'] &&
          !shift.errorMessages['capacity']
        );
      });

    return Object.keys(errorMessages).length === 0 && this.state.weekdays.length > 0;
  };

  onAddShift = () => {
    const { idx, rateTypeId, payRate } = this.props.shiftLocation;
    const { zipCode } = this.props.shiftLocation.address;
    this.props.addShift({ locationId: idx, zipCode, rateTypeId, payRate });
  };

  canDeleteShift = (idx = 'last') => {
    if (idx === 'last') return this.props.shifts.length > 1;
    return this.props.shifts.length > 1 && this.props.shifts.length - 1 > idx;
  };

  canGenerateShifts = () =>
    this.props.shifts.length === 0 &&
    this.props.post.details.scheduleType === scheduleTypes.RECURRING;

  render() {
    const { shifts, wasValidated, shiftLocation } = this.props;
    const timezone = getTimezone(shiftLocation);
    const lastShift = get(shifts, `[${get(shifts, 'length', 1) - 1}]`, {});
    const lastShiftIdx = lastShift?.idx;

    return (
      <div className="recurringSchedule">
        <header>
          <div className="screen-title">Schedule</div>
        </header>
        <div className="content mb-5">
          {this.canGenerateShifts() && (
            <ShiftGenerator
              {...this.state}
              setSchedule={this.setSchedule}
              setWeekdays={this.setWeekdays}
              setCapacity={this.setCapacity}
              wasValidated={this.state.wasValidated}
              timezone={timezone}
            />
          )}
          {shifts.map((shift, idx) => (
            <OneTimeShift
              from="recurring"
              key={idx}
              idx={shift.idx}
              mapIdx={idx}
              shift={shift}
              hasBottomBorder={idx !== shifts.length - 1}
              canDelete={this.canDeleteShift(idx)}
              wasValidated={wasValidated}
              onChangeField={this.props.onChangeField(shift.idx)}
              onChangeShift={this.props.onChangeShift(shift.idx)}
              onDeleteShift={this.props.onDeleteShift(shift.idx)}
              user={this.props.user}
              eventName={this.props.eventName}
              timezone={timezone}
            />
          ))}
          {!this.canGenerateShifts() && (
            <div className="row m-0">
              <AddButton onClick={this.onAddShift}>ADD ANOTHER SHIFT</AddButton>
              <DeleteButton
                show={this.canDeleteShift()}
                className="ml-2"
                onClick={this.setDeleteModal}
              >
                DELETE SHIFT
              </DeleteButton>
              <ConfirmationModal
                isOpen={this.state.openDeleteModal}
                toggle={this.setDeleteModal}
                title="Delete shift"
                body={`Are you sure you want to delete this shift on ${moment(
                  lastShift?.startDate,
                  MILITARY_FORMAT
                ).format('MM/DD/YYYY')}?`}
                onSave={this.props.onDeleteShift(lastShiftIdx)}
                cancelText="Go Back"
                confirmText="Delete"
                onCancel={this.setDeleteModal}
              />
            </div>
          )}
        </div>
        <ActionBar
          editMode={this.props.editMode}
          backTo={this.backTo()}
          currentStep="schedule/recurring"
          onSaveAndContinue={this.onSaveAndContinue}
          nextDisabled={() => !this.pageIsValid()}
        />
      </div>
    );
  }

  onSaveAndContinue = () => {
    const { idx, address } = this.props.shiftLocation;
    const { baseUrl, post, authenticated } = this.props;

    if (!this.pageIsValid()) {
      this.setState({ ...this.state, wasValidated: true });
      this.props.validateForm();
      return;
    }

    if (this.canGenerateShifts()) {
      this.props.generateRepeatedShifts({
        locationId: idx,
        zipCode: address.zipCode,
        capacity: this.state.capacity,
        schedule: {
          weekDays: this.state.weekdays,
          ...this.state.schedule,
        },
      });
      return;
    }

    const callback = () => {
      this.props.history.push(`${baseUrl}/rate`);
    };

    if (!authenticated) {
      callback();
      return;
    }

    this.props.saveDraft(post, callback);
  };
}

const mapStateToProps = (state) => {
  const { post } = state;
  const { wasValidated } = post.validations.shifts;
  const shiftLocation = post.locations[post.navigation.locationIdx];
  const shifts = shiftsByLocation(post.shifts, shiftLocation);
  return {
    post,
    wasValidated,
    shiftLocation,
    authenticated: state.session.authenticated,
    shifts,
    user: state.session.currentUser,
    eventName: state.post.profile.title,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  generateRepeatedShifts: (payload) => dispatch(repeatShifts(payload)),
  addShift: (payload) => dispatch(addShift(payload)),
  onDeleteShift: (index) => () => dispatch(removeShift({ index })),
  onChangeField: (index) => (name, value) => dispatch(changeShiftField({ index, name, value })),
  onChangeShift: (index) => (shift) => dispatch(changeShift({ index, shift })),
  saveDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'recurring' })),
  validateForm: () => dispatch(validateForm('shifts')),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurringSchedule);
