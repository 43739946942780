import React, { Component } from 'react'
import moment from 'moment-timezone';
import { get } from 'lodash';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { formatCurrency } from 'helpers/dashboard';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { formatTimeTz, formatTableDateTz } from 'helpers/datetime';

export default class FinancesJobItem extends Component {
  computeTotalPayment = () => {
    const { job } = this.props;
    const jobPayment = get(job, 'payment.eoTotalCharge', 0);
    const jobModificationPayment = get(job, 'jobModification.payment.eoTotalCharge', 0);
    return (Number(jobPayment) + Number(jobModificationPayment));
  }

  render() {
    const { job } = this.props;
    const { user, shiftLocation } = job;
    const jobModification = get(job, 'jobModification', {});
    const tz = zipcode_to_timezone.lookup(shiftLocation.address.zipCode) || moment.tz.guess();

    const startTime = jobModification.startTime || job.startTime;
    const endTime = jobModification.endTime || job.endTime;
    const payRate = jobModification.payRate || job.payRate;
    const rateTypeId = jobModification.rateTypeId || job.rateTypeId

    return (
      <div className='finances-job-item'>
        <div className='col-md-2'>{user.name}</div>
        <div className='col-md-1'>{formatTableDateTz(startTime, tz)}</div>
        <div className='col-md-2'>{`${formatTimeTz(startTime, tz)} - ${formatTimeTz(endTime, tz)}`}</div>
        <div className='col-md-3'>{`${shiftLocation.address.city}, ${shiftLocation.address.state}`}</div>
        <div className='col-md-1'>{
          `${formatCurrency({
            currency: payRate,
            dollarSign: true,
          })}
          /
          ${rateTypeNameFinder(this.props.rateTypes, rateTypeId)}`
        }</div>
        <div className='col-md-2'>{formatCurrency({
          currency: this.computeTotalPayment(),
          dollarSign: true,
        })}</div>
        <div className='col-md-1'>{formatTableDateTz(get(job, 'payment.paidAt'), tz)}</div>
      </div>
    )
  }
}
