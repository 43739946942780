import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { startsWith } from 'lodash';
import VerticalRule from 'components/VerticalRule';
import HorizontalRule from 'components/HorizontalRule';

const domain = process.env.REACT_APP_DOMAIN;

class VenueMap extends Component {
  fileReader = new FileReader();
  state = {
    modalOpen: false,
  };
  constructor() {
    super();
    this.fileReader.addEventListener('load', () => {
      this.props.onMapLoad(this.fileReader.result);
    });
  }

  togglePreviewModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  onMapChange = (ev: SyntheticEvent<HTMLInputElement>) => {
    const file = ev.currentTarget.files[0];
    this.fileReader.readAsDataURL(file);
    this.props.updateVenueMapName(file.name);
  };

  urlFor = (previewUrl) => (startsWith(previewUrl, '/') ? domain + previewUrl : previewUrl);

  render() {
    const { imageSrc, fileName } = this.props;

    return (
      <div className="venue-map-v2">
        <div className="section-header gotham-bold-font col">Facility Map</div>
        <section>
          <div className="row mx-0">
            <div className="col-md-5 px-0">
              <div className="input-section">
                <p className="upload-label nimbus-regular-font">If you have a facility map, upload it here.</p>
                <input
                  onChange={this.onMapChange}
                  type="file"
                  name="venue_map"
                  id="venue_map"
                  className="custom-file-input"
                  accept="image/*"
                />
                <label
                  className="btn btn-primary custom-file-label gotham-bold-font"
                  htmlFor={['file_label_type', 'venue_map']}
                >
                  Upload Map
                </label>
              </div>
            </div>
            <div className='col-md-1 d-flex'>
              <VerticalRule dashed margin='0px' />
            </div>
            <HorizontalRule dashed />
            <div className="col-md-6 col-sm-12 venue-section nimbus-regular-font px-0">
              <p className='file-label'>
                Map File Uploaded:
              </p>
              {!imageSrc && (
                <p className='font-italic file-label-copy'>No file uploaded</p>
              )}
              {imageSrc && (
                <div className="nimbus-regular-font venue-map-file" onClick={this.togglePreviewModal}>{fileName}</div>
              )}
            </div>
          </div>
        </section>
        <Modal
          modalClassName="venue-map-modal"
          isOpen={this.state.modalOpen}
          toggle={this.togglePreviewModal}
        >
          <ModalHeader toggle={this.togglePreviewModal}>Venue Map</ModalHeader>
          <ModalBody>
            <img src={this.urlFor(imageSrc) || '/event-logo-default.png'} alt="A preview of the venue map" />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default VenueMap;
