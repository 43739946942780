import { ofType } from 'redux-observable';
import { ignoreElements, map, switchMap } from 'rxjs/operators';
import { basicEpic, loadFromStorage, mappingEpic, writeToStorage } from 'redux/ducks/helpers';
import * as actions from './actions';
import EventService from 'services/v2/EventService';
import { isEqual } from 'lodash';
import { Shift } from './Shifts';
import { success, error } from 'redux/ducks/Flash';
import { Observable } from 'rxjs';
import * as locationActions from './Locations/actions';
import * as eventActions from 'redux/ducks/Event';
import * as shiftActions from './Shifts/actions';
import * as navigationActions from './Navigation';
import { HIDE_SPINNER, SHOW_SPINNER } from '../LoadingSpinner';
import { isId } from 'helpers/post';

// Epics
// ========================================================
export const clearCachedFormEpic = (action$, store) => {
  return action$.pipe(
    ofType(actions.V2_CLEAR_FORM),
    map(() => localStorage.removeItem(actions.POST_STORAGE_KEY)),
    ignoreElements()
  );
};

export const saveFormEpic = basicEpic(actions.V2_SAVE_LOCAL_FORM, (_, state) => {
  writeToStorage(actions.POST_STORAGE_KEY, state.post);
  return Promise.resolve();
});

export const reloadFormEpic = mappingEpic(actions.V2_RELOAD_FORM, () => ({
  type: actions.V2_RELOAD_FORM_SUCCESS,
  payload: loadFromStorage(actions.POST_STORAGE_KEY),
}));

export const saveDraftEpic = basicEpic(
  actions.SAVE_AS_DRAFT,
  ({ callback, from, post }, { session }) => {
    const { profile } = post;
    if (!session.authenticated || !isId(profile.settingDetailId) || !isId(profile.eventSettingId))
      return Promise.reject({ message: 'Please select a setting and a job type' });

    const data = {
      ...post,
      details: {
        ...post.details,
        id: post.details.id || null,
        eventOperatorId: post.details.eventOperatorId || session.currentUser.id,
        isDraft: !post.details.id || post.details.isDraft,
      },
      shifts: post.shifts.filter((s) => !isEqual(s, new Shift())),
    };
    const cancelledShifts = data.shifts.filter(({ cancelled }) => cancelled).map(({ id }) => id);

    return new EventService(session).createOrUpdate(data, cancelledShifts).then((result) => {
      if (callback) callback();
      return { ...result, from };
    });
  }
);

export const publishDraftEpic = basicEpic(actions.PUBLISH_DRAFT, ({ history }, state) => {
  state.post.details.isDraft = false;
  return new EventService(state.session).update(state.post).then((result) => {
    history.push('/v2/upcoming-events');
    return result;
  });
});

export const editEventEpic = basicEpic(actions.UPDATE_EVENT, ({ history }, state) => {
  return new EventService(state.session).update(state.post).then((result) => {
    history.replace('/v2/upcoming-events');
    return result;
  });
});

export const deleteDocumentEpic = basicEpic(actions.DELETE_DOCUMENT, ({ documentId }, state) => {
  return new EventService(state.session).deleteDocument({
    documentId,
    eventId: state.post.details.id,
  });
});

export const deleteShiftDocumentEpic = basicEpic(
  locationActions.DELETE_SHIFT_DOCUMENT,
  ({ documentId }, state) => {
    return new EventService(state.session).deleteShiftDocument({
      documentId,
      eventId: state.post.details.id,
    });
  }
);

// Mapping Epics
// ========================================================

export const clearFormEpic = mappingEpic(
  [actions.PUBLISH_DRAFT_SUCCESS, actions.UPDATE_EVENT_SUCCESS],
  actions.V2_CLEAR_FORM
);

const successMessages = {
  [actions.UPDATE_EVENT_SUCCESS]: 'Your job has been successfully updated',
  [actions.SAVE_AS_DRAFT_SUCCESS]: 'Draft has been saved',
  [actions.PUBLISH_DRAFT_SUCCESS]: 'Your job has been successfully published',
};

export const successMessagesEpic = mappingEpic(
  [actions.UPDATE_EVENT_SUCCESS, actions.SAVE_AS_DRAFT_SUCCESS, actions.PUBLISH_DRAFT_SUCCESS],
  ({ type }) => success(successMessages[type])
);

const errorMessages = {
  [actions.UPDATE_EVENT_ERROR]: 'Sorry! Could not update job',
  [actions.SAVE_AS_DRAFT_ERROR]: 'Sorry! Could not save draft',
};

export const errorMessagesEpic = mappingEpic(
  [actions.UPDATE_EVENT_ERROR, actions.SAVE_AS_DRAFT_ERROR],
  ({ type, payload }) => error(`${errorMessages[type]}: ${payload.message}`)
);

export const localSavePostEpic = mappingEpic(
  [
    actions.SAVE_AS_DRAFT_SUCCESS,
    locationActions.ADD_MULTIPLE_LOCATIONS,
    actions.INITIALIZE_POST,
    actions.DUPLICATE_POST,
    navigationActions.SET_LOCATION_IDX,
  ],
  actions.V2_SAVE_LOCAL_FORM
);

export const triggerAddMultipleLocations = (action$, store) => {
  return action$.pipe(
    ofType(actions.SAVE_AS_DRAFT_SUCCESS),
    switchMap((action) => {
      const { post } = store.getState();
      if (
        !post.details.locationsNo ||
        post.locations.length === post.details.locationsNo
      )
        return Observable.empty();

      return Observable.of(
        locationActions.addMultipleLocations({ locationsNumber: post.details.locationsNo })
      );
    })
  );
};

export const saveAfterLocationDeleted = (action$, store) => {
  return action$.pipe(
    ofType(locationActions.DELETE_LOCATION),
    map(() => {
      const { post } = store.getState();
      return {
        type: actions.SAVE_AS_DRAFT,
        payload: { post, from: 'location-view' },
      };
    })
  );
};

export const saveAfterDocumentsDeleted = (action$, store) => {
  return action$.pipe(
    ofType(locationActions.DELETE_SHIFT_DOCUMENT),
    map(() => {
      const { post } = store.getState();
      return {
        type: actions.SAVE_AS_DRAFT,
        payload: { post, from: 'details' },
      };
    })
  );
};

export const reloadUpcomingEventsEpic = (action$, store) =>
  action$.pipe(
    ofType(
      actions.UPDATE_EVENT_SUCCESS,
      actions.PUBLISH_DRAFT_SUCCESS,
      shiftActions.REMOVE_LOCATION_SUCCESS,
      shiftActions.DELETE_SHIFT_SUCCESS
    ),
    map(() => ({ type: eventActions.GET_UPCOMING_EVENTS, payload: { disableSpinner: true } }))
  );

export const updateUpcomingEventsEpic = (action$, store) =>
  action$.pipe(
    ofType(actions.SAVE_AS_DRAFT_SUCCESS),
    switchMap((action) => {
      if (['summary', 'agenda', 'location', 'review-eap'].includes(action.payload.from))
        return Observable.of({
          type: eventActions.GET_UPCOMING_EVENTS,
          payload: { disableSpinner: true },
        });

      return Observable.empty();
    })
  );

export const showSpinnerEpic = mappingEpic(
  [actions.UPDATE_EVENT, actions.DELETE_DOCUMENT],
  SHOW_SPINNER
);

export const hideSpinnerEpic = mappingEpic(
  [actions.DELETE_DOCUMENT_SUCCESS, actions.DELETE_DOCUMENT_ERROR],
  HIDE_SPINNER
);
