import React, { Component } from 'react';
import {
  changeShiftField,
  removeShift,
  addShift,
  changeShift,
} from 'redux/ducks/Post/Shifts/actions';
import { connect } from 'react-redux';
import ActionBar from '../../ActionBar';
import { validateForm } from 'redux/ducks/Post';
import { saveAsDraft } from 'redux/ducks/Post/actions';
import OneTimeShift from '../../Shift/OneTimeShift';
import { AddButton, DeleteButton } from 'components/V2/Buttons';
import { get } from 'lodash';
import {
  scheduleTypes,
  shiftsByLocation,
  trackMixpanelPageView,
  isEndTimeAfterNow,
  getTimezone,
} from 'helpers/post';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import moment from 'moment-timezone';
import ToolTip from 'scenes/V2/ToolTip';
import { MILITARY_FORMAT } from 'helpers/datetime';

class OneTimeSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
    };
  }

  componentDidMount() {
    if (!this.props.editMode)
      trackMixpanelPageView('create-event/schedule/one-time', this.props.user);

    if (!this.props.shifts.length) this.onAddShift();

    const { hash } = this.props.location;
    const idx = hash.replace('#', '');
    if (!idx) return;
    setTimeout(() => {
      const el = document.getElementById(idx);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 500);
  }

  handleDeleteModal = () => {
    this.setState((prev) => ({
      ...prev,
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    }));
  };

  onAddShift = () => {
    const { idx, rateTypeId, payRate } = this.props.shiftLocation;
    const { zipCode } = this.props.shiftLocation.address;
    this.props.addShift({ locationId: idx, zipCode, rateTypeId, payRate });
  };

  canDeleteShift = (idx = 'last') => {
    if (idx === 'last') return this.props.shifts.length > 1;
    return this.props.shifts.length > 1 && this.props.shifts.length - 1 > idx;
  };

  render() {
    const { shifts, baseUrl, wasValidated, shiftLocation } = this.props;
    const lastShift = get(shifts, `[${get(shifts, 'length', 1) - 1}]`, {});
    const lastShiftIdx = lastShift?.idx;
    const timezone = getTimezone(shiftLocation);

    return (
      <div className="oneTimeSchedule">
        <header>
          <div className="screen-title">Schedule</div>
        </header>
        <div className="content mb-5">
          {shifts.map((shift, idx) => (
            <OneTimeShift
              from="oneTime"
              key={idx}
              idx={shift.idx}
              mapIdx={idx}
              shift={shift}
              shiftCount={shifts.length}
              hasBottomBorder={idx !== shifts.length - 1}
              canDelete={this.canDeleteShift(idx)}
              wasValidated={wasValidated}
              onChangeField={this.props.onChangeField(shift.idx)}
              onChangeShift={this.props.onChangeShift(shift.idx)}
              onDeleteShift={this.props.onDeleteShift(shift.idx)}
              user={this.props.user}
              eventName={this.props.eventName}
              timezone={timezone}
            />
          ))}
          <div className="row m-0">
            {shifts[0]?.isMultiDay || (
              <>
                <AddButton onClick={this.onAddShift}>ADD ANOTHER SHIFT</AddButton>
                {shifts.length === 1 && (
                  <ToolTip
                    id="add-another-shift"
                    top="2px"
                    bodyText="Select this option to add an additional time or date. With this option, ATs can apply to each individual shift. This option allows for an hourly rate or fixed pay rate."
                  />
                )}
              </>
            )}
            <DeleteButton
              className="ml-2"
              show={this.canDeleteShift()}
              onClick={this.handleDeleteModal}
            >
              DELETE SHIFT
            </DeleteButton>
            <ConfirmationModal
              isOpen={this.state.isDeleteModalOpen}
              title="Delete shift"
              body={`Are you sure you want to delete this shift ${moment(
                lastShift?.startDate,
                MILITARY_FORMAT
              ).format('MM/DD/YYYY')}?`}
              toggle={this.handleDeleteModal}
              onSave={this.props.onDeleteShift(lastShiftIdx)}
              confirmText="Delete"
              cancelText="Go Back"
            />
          </div>
        </div>
        <ActionBar
          editMode={this.props.editMode}
          backTo={`${baseUrl}/schedule/type`}
          currentStep={`schedule/${scheduleTypes.ONETIME}`}
          onSaveAndContinue={this.onSaveAndContinue}
          nextDisabled={() => !this.pageIsValid()}
        />
      </div>
    );
  }

  pageIsValid = () => {
    const { isDraft } = this.props.post.details;
    if (!isDraft && !this.props.shifts.length) return true;

    const shifts = isDraft
      ? this.props.shifts
      : this.props.shifts.filter(({ endTime }) =>
          isEndTimeAfterNow(moment(endTime, MILITARY_FORMAT).format())
        );

    return shifts.every((shift) => {
      return (
        !shift.errorMessages['startDate'] &&
        !shift.errorMessages['start'] &&
        !shift.errorMessages['end'] &&
        !shift.errorMessages['capacity']
      );
    });
  };

  onSaveAndContinue = () => {
    const { baseUrl, post } = this.props;

    if (!this.pageIsValid()) {
      this.props.validateForm();
      return;
    }

    const callback = () => {
      this.props.history.push(`${baseUrl}/rate`);
    };

    if (!this.props.authenticated) {
      callback();
      return;
    }

    this.props.saveDraft(post, callback);
  };
}

const mapStateToProps = (state) => {
  const { post } = state;
  const { wasValidated } = post.validations.shifts;
  const shiftLocation = post.locations[post.navigation.locationIdx];
  const shifts = shiftsByLocation(post.shifts, shiftLocation);
  return {
    post,
    wasValidated,
    shiftLocation,
    authenticated: state.session.authenticated,
    shifts,
    user: state.session.currentUser,
    eventName: state.post.profile.title,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addShift: (payload) => dispatch(addShift(payload)),
  onDeleteShift: (index) => () => dispatch(removeShift({ index })),
  onChangeField: (index) => (name, value) => dispatch(changeShiftField({ index, name, value })),
  onChangeShift: (index) => (shift) => dispatch(changeShift({ index, shift })),
  saveDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'one-time' })),
  validateForm: () => dispatch(validateForm('shifts')),
});

export default connect(mapStateToProps, mapDispatchToProps)(OneTimeSchedule);
