// @flow
import { EventLocationSerializer } from 'services/serializers';
import ReportToContactSerializer from '../ReportToContactSerializer';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';
import DetailsSerializer from './DetailsSerializer';
import { checkIsMultiDayShift, getTimezone } from 'helpers/post';
import { MILITARY_FORMAT } from 'helpers/datetime';

export default class ShiftSerializer {
  static deserialize(shift, eventRate = 0) {
    const tz =
      zipcode_to_timezone.lookup(shift.event_location.address.zip_code) || moment.tz.guess();

    const formatTime = (time, format) => (time.isValid() ? time.format(format) : '');
    const endTime = moment.tz(shift.end_time, tz);
    const startTime = moment.tz(shift.start_time, tz);

    const result = {
      id: shift.id,
      startTime: formatTime(startTime, 'MM-DD-YYYY HH:mm'),
      endTime: formatTime(endTime, 'MM-DD-YYYY HH:mm'),
      startDate: formatTime(startTime, 'MM-DD-YYYY'),
      endDate: formatTime(endTime, 'MM-DD-YYYY'),
      start: formatTime(startTime, 'HH:mm'),
      end: formatTime(endTime, 'HH:mm'),
      capacity: shift.shift_capacity,
      emergencyActionPlanId: shift.emergency_action_plan_id,
      reportToContacts: shift.shift_contacts?.map(ReportToContactSerializer.deserialize),
      schedule: shift.schedule,
      approxHours: shift.approx_hours,
      notes: shift.notes,
      responsibilities: shift.responsibilities,
      isMultiDay: checkIsMultiDayShift({
        startTime: startTime.format(),
        endTime: endTime.format(),
      }),
      instructions: shift.instructions,
      contractInfo: shift.contract_info || {},
      payRate: shift.pay_rate || eventRate,
      rateTypeId: shift.rate_type_id?.toString() || '0',
      cancelled: shift.cancelled,
      errorMessages: {
        reportToContacts: [{}],
      },
      supplies: DetailsSerializer.deserialize(shift.details),
      zipCode: shift.event_location.address.zip_code,
    };

    if (shift.no_fields) {
      result.countType = 'noFields';
      result.countValue = shift.no_fields;
    } else if (shift.no_mats) {
      result.countType = 'noMats';
      result.countValue = shift.no_mats;
    } else if (shift.no_courts) {
      result.countType = 'noCourts';
      result.countValue = shift.no_courts;
    } else if (shift.no_rinks) {
      result.countType = 'noRinks';
      result.countValue = shift.no_rinks;
    } else {
      result.countType = 'noFields';
      result.countValue = 0;
    }

    if (shift.event_location) {
      result['locationId'] = shift.event_location.id;
    }

    return result;
  }

  static serialize(shift, location) {
    const timeZone = getTimezone(location);

    const result = {
      start_time: moment(shift.startTime, MILITARY_FORMAT).isValid()
        ? moment.tz(shift.startTime, MILITARY_FORMAT, timeZone).tz('UTC').format()
        : '',
      end_time: moment(shift.endTime, MILITARY_FORMAT).isValid()
        ? moment.tz(shift.endTime, MILITARY_FORMAT, timeZone).tz('UTC').format()
        : '',
      shift_capacity: shift.capacity,
      cancelled: shift.cancelled,
      event_location_attributes: location && EventLocationSerializer.serialize(location),
      shift_contacts_attributes: this.mergeContacts(
        shift.reportToContacts,
        location?.reportToContacts
      ).map(ReportToContactSerializer.serialize),
      no_fields: location?.countValue,
      schedule: shift.schedule,
      approx_hours: shift.approxHours,
      notes: shift.notes,
      responsibilities: shift.responsibilities,
      contract_info: shift.contractInfo || {},
      pay_rate: shift.payRate,
      rate_type_id: shift.rateTypeId,
      instructions: location?.instructions,
      details: location && {
        ...DetailsSerializer.serialize(location.supplies),
      },
    };

    if (shift.id) {
      result.id = shift.id;
    }

    if (shift.cancelled) {
      result.cancel_reason = shift.cancelReason || 'Not specified';
    }

    if (shift.locationCancelled) {
      result.location_cancelled = true;
    }

    return result;
  }

  static mergeContacts(shiftContacts, locationContacts = []) {
    const newShiftContacts = [];
    let locationContactsCopy = [...locationContacts];

    for (const shiftContact of shiftContacts) {
      const locationContact = locationContactsCopy.shift();

      if (locationContact) {
        const { name, email, phoneNumber } = locationContact;
        newShiftContacts.push({
          id: shiftContact.id,
          name,
          email,
          phoneNumber,
        });
      } else {
        newShiftContacts.push({ id: shiftContact.id, _destroy: true });
      }
    }

    for (const locationContact of locationContactsCopy) {
      const { name, email, phoneNumber } = locationContact;
      newShiftContacts.push({ name, email, phoneNumber });
    }

    return newShiftContacts;
  }
}
