import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { UncontrolledAlert } from 'reactstrap';
import { createSession, clearErrors } from 'redux/ducks/Session';
import { capitalize } from 'lodash';

class LoginScene extends Component {
  componentDidMount() {
    this.props.clearErrors();
  }

  onSubmit = ({ email, password, rememberMe }) => {
    this.props.login({
      email: email,
      password: password,
      rememberMe: rememberMe,
      role: this.props.role,
      history: this.props.history,
    });
  };

  onChooseTitle = () => {
    if (!this.props.role)
      return (
        <div className="v2-title gotham-bold-font">
          <p className="v2-title-p">Log In</p>
        </div>
      );
    return (
      <div className="v2-title gotham-bold-font">
        <p className="v2-title-p">{capitalize(this.props.role)} Portal Log In</p>
      </div>
    );
  };

  render() {
    const { error, sessionExpired } = this.props;
    const showErrorAlert =
      !sessionExpired &&
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.includes('Invalid login credentials. Please try again.');

    return (
      <React.Fragment>
        <div className="v2LoginScene gotham-regular-font">
          {this.onChooseTitle()}
          {showErrorAlert && (
            <UncontrolledAlert color="danger" className="alert-margin nimbus-regular-font">
              Invalid email or password
            </UncontrolledAlert>
          )}
          <LoginForm {...this.props} onSubmit={this.onSubmit} role={this.props.role} from="login" />
        </div>
        <a className="atSignUpHyperlink" href={`${process.env.REACT_APP_DEEPLINK_URL}/sign-up`}>
          Are you an athletic trainer? Click here.
        </a>
      </React.Fragment>
    );
  }
}

const mapStateToProp = (state, ownProps) => {
  const { session } = state;
  const { error, authenticated, currentUser, sessionExpired } = session;

  if (!error && session && authenticated) {
    if (currentUser.role === 'Parent') {
      ownProps.history.push('/parent/dashboard');
    } else if (currentUser.role === 'Patient') {
      ownProps.history.push('/patient/dashboard');
    } else {
      ownProps.history.push('/dashboard');
    }
  }

  return {
    error,
    sessionExpired,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(createSession(credentials)),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProp, mapDispatchToProps)(LoginScene);
