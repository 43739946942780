import React from 'react';
import InputOrFlatpickr from 'components/InputOrFlatpickr';
import classNames from 'classnames';
import moment from 'moment';
import Tip from 'scenes/V2/Tip';
import { Shift } from 'redux/ducks/Post/Shifts';
import { DATE_PICKER_OPTIONS, TIME_PICKER_OPTIONS, shiftDuration } from 'helpers/post';
import { hour24ToMeridiem, meridiemTo24, MILITARY_FORMAT } from 'helpers/datetime';
import { DeleteButton } from 'components/V2/Buttons';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import DeleteModal from 'components/V2/ConfirmationModal/DeleteModal';
import ToolTip from 'scenes/V2/ToolTip';

type Props = {
  idx: number,
  shift: Shift,
  shiftCount: number,
  hasBottomBorder: boolean,
  canDelete: boolean,
  wasValidated: boolean,
  dateDisabled: boolean,
  onChangeField: Function,
  onDeleteShift: Function,
};

const OneTimeShift: React.FC<Props> = (props) => {
  const startDateRef = React.useRef(null);
  const { idx, mapIdx, hasBottomBorder, canDelete, wasValidated, dateDisabled, shift, editMode } =
    props;

  const [validated, validateField] = React.useState(INITIAL_VALIDATION_STATE);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const showError = (field) => wasValidated && !!shift.errorMessages[field];
  const onFieldBlur = (field) => () => validateField({ ...validated, [field]: true });
  const onCapacity = (amount) => props.onChangeField('capacity', amount);
  const toggleIsMultiDay = () => {
    const downFront = shift.isMultiDay;
    props.onChangeField('isMultiDay', !shift.isMultiDay);
    if (downFront) props.onChangeField('startDate', shift.startDate);
  };
  const onFlatpickr = (name) => (value) => props.onChangeField(name, value);
  const onTimeFlatpickr = (name) => (value) => props.onChangeField(name, meridiemTo24(value));

  const addStaff = (amount) => () => {
    const newCapacity = Number(shift.capacity) + amount;
    onCapacity(newCapacity < 1 ? 1 : newCapacity);
  };

  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const renderStartDate = () => {
    return (
      <div
        className={classNames('', {
          'col-md-4': !shift.isMultiDay,
          'col-md-6': shift.isMultiDay,
        })}
      >
        <label htmlFor="startDate">{shift.isMultiDay ? 'Start Date' : 'Shift Date'}</label>
        <div className={classNames('input-group', { disabled: dateDisabled })}>
          <InputOrFlatpickr
            ref={startDateRef}
            type="datetime"
            name={'startDate'}
            value={shift.startDate}
            options={DATE_PICKER_OPTIONS}
            onChange={onFlatpickr('startDate')}
            onBlur={onFieldBlur('startDate')}
            className={classNames('form-control lbr nimbus-regular-font', {
              'is-invalid': showError('startDate'),
            })}
            placeholder={moment().format('MM-DD-YYYY')}
          />
          <div className="input-group-append">
            <span
              className="input-group-text rbr cursor-pointer"
              onClick={() => {
                const input = document.querySelector(`input[name="startDate"]`);
                if (input) input.focus();
              }}
            >
              <i className="far fa-calendar-alt"></i>
            </span>
          </div>
          <div className="invalid-feedback">{shift.errorMessages['startDate']}</div>
        </div>
      </div>
    );
  };

  const renderEndDate = () => {
    if (!shift.isMultiDay) return null;

    return (
      <div
        className={classNames('', {
          'col-md-4': !shift.isMultiDay,
          'col-md-6': shift.isMultiDay,
        })}
      >
        <label htmlFor="endDate">End Date</label>
        <div className={classNames('input-group', { disabled: dateDisabled })}>
          <InputOrFlatpickr
            type="datetime"
            name={'endDate'}
            value={shift.endDate}
            options={DATE_PICKER_OPTIONS}
            onChange={onFlatpickr('endDate')}
            onBlur={onFieldBlur('endDate')}
            className={classNames('form-control lbr nimbus-regular-font', {
              'is-invalid': showError('endDate'),
            })}
            placeholder={moment().format('MM-DD-YYYY')}
          />
          <div className="input-group-append">
            <span
              className="input-group-text rbr cursor-pointer"
              onClick={() => {
                const input = document.querySelector(`input[name="endDate"]`);
                if (input) input.focus();
              }}
            >
              <i className="far fa-calendar-alt"></i>
            </span>
          </div>
          <div className="invalid-feedback">{shift.errorMessages['startDate']}</div>
        </div>
      </div>
    );
  };

  const renderStartHours = (reused = false) => {
    if (shift.isMultiDay && !reused) return null;

    return (
      <div
        className={classNames('', {
          'col-md-4': !reused,
          'col-md-6': reused,
        })}
      >
        <label htmlFor="start">Start Time</label>
        <div className="input-group">
          <InputOrFlatpickr
            name="start"
            value={hour24ToMeridiem(shift.start)}
            options={TIME_PICKER_OPTIONS}
            type="datetime"
            onValueUpdate={onTimeFlatpickr('start')}
            onBlur={onFieldBlur('start')}
            className={classNames('form-control fbr nimbus-regular-font', {
              'is-invalid': showError('start'),
            })}
            placeholder="12:00 PM"
            timezone={props.timezone}
          />
          <div className="invalid-feedback">{shift.errorMessages['start']}</div>
        </div>
      </div>
    );
  };

  const renderEndHours = (reused = false) => {
    if (shift.isMultiDay && !reused) return null;

    return (
      <div
        className={classNames('', {
          'col-md-4': !reused,
          'col-md-6': reused,
        })}
      >
        <label htmlFor="end">End Time</label>
        <div className="input-group">
          <InputOrFlatpickr
            name="end"
            value={hour24ToMeridiem(shift.end)}
            options={TIME_PICKER_OPTIONS}
            type="datetime"
            onValueUpdate={onTimeFlatpickr('end')}
            onBlur={onFieldBlur('end')}
            className={classNames('form-control fbr nimbus-regular-font', {
              'is-invalid': showError('end'),
            })}
            placeholder="2:00 PM"
            timezone={props.timezone}
          />
          <div className="invalid-feedback">{shift.errorMessages['end']}</div>
        </div>
      </div>
    );
  };

  const renderMultiDayHours = () => {
    if (!shift.isMultiDay) return null;

    return (
      <div className="row mt-3">
        {renderStartHours(true)}
        {renderEndHours(true)}
      </div>
    );
  };

  const renderMultiDayToggler = () => {
    const { from = 'oneTime', shiftCount } = props;
    if (from !== 'oneTime' || shiftCount > 1) return null;

    return (
      <div className="row multi-day">
        <div className={classNames(`col-md-12`)}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`multi-day-shift-${idx}`}
              checked={shift.isMultiDay}
              onChange={toggleIsMultiDay}
            />
            <label
              className="custom-control-label"
              htmlFor={`multi-day-shift-${idx}`}
              style={{ paddingTop: '2px' }}
            >
              Combine Multiple Days into One Shift
              <ToolTip
                id="multi-day-shift-icon"
                top="3px"
                bodyText="Select this option to combine several days into a single shift, requiring ATs to work all included days. Note that this option only allows for a fixed pay rate across all days and does not allow hourly rates."
              />
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      key={idx}
      className={classNames('one-time-shift', { 'bottom-line': hasBottomBorder })}
      id={`s-${idx}`}
    >
      <div className="shiftName">
        <div className="shiftName gotham-bold-font">
          {`Shift ${mapIdx + 1}`}
          <span className="pl-1 text-secondary hrs">({shiftDuration(shift)}h)</span>
        </div>
      </div>
      <div className="row">
        {renderStartDate()}
        {renderEndDate()}
        {renderStartHours()}
        {renderEndHours()}
      </div>
      {renderMultiDayHours()}
      <br />
      <div className="row">
        <div className="col-md-12">
          <label htmlFor="capacity">How many Athletic Trainers do you need for this shift?</label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="input-group">
            <div className="input-group-append">
              <span
                type="button"
                onClick={addStaff(-1)}
                className="input-group-text lbr remove-border-r"
              >
                <i className="fas fa-minus"></i>
              </span>
            </div>
            <input
              type="number"
              name="capacity"
              value={shift.capacity}
              onChange={(e) => onCapacity(e.target.value)}
              onBlur={onFieldBlur('capacity')}
              className={classNames('form-control text-center pl-4 gotham-bold-font', {
                'is-invalid': showError('capacity'),
              })}
              placeholder="1"
              min={1}
            />
            <div className="input-group-append">
              <span type="button" onClick={addStaff(1)} className="input-group-text rbr">
                <i className="fas fa-plus"></i>
              </span>
            </div>
            <div className="invalid-feedback">{shift.errorMessages['capacity']}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="pt-2 col-md-8">
          {idx === 0 && <Tip text="It’s recommended to have 1 AT for every 2 fields" />}
        </div>
      </div>

      {renderMultiDayToggler()}

      <DeleteButton show={canDelete} className="pt-2" onClick={handleDeleteModal}>
        DELETE SHIFT
      </DeleteButton>
      {editMode ? (
        <DeleteModal
          isOpen={openDeleteModal}
          title="Delete shift"
          body={`Deleting this shift on ${moment(shift.startDate, MILITARY_FORMAT).format(
            'MM/DD/YYYY'
          )} will remove athletic trainers who applied or confirmed to work.`}
          toggle={handleDeleteModal}
          onDelete={props.onDeleteShift}
          item={shift}
          user={props.user}
          eventName={props.eventName}
        />
      ) : (
        <ConfirmationModal
          isOpen={openDeleteModal}
          toggle={handleDeleteModal}
          title="Delete shift"
          body={`Are you sure you want to delete this shift on ${moment(
            shift.startDate,
            MILITARY_FORMAT
          ).format('MM/DD/YYYY')}?`}
          onSave={() => props.onDeleteShift()}
          cancelText="Go Back"
          confirmText="Delete"
          onCancel={handleDeleteModal}
        />
      )}
    </div>
  );
};

export default OneTimeShift;

const INITIAL_VALIDATION_STATE = {
  start: false,
  end: false,
  capacity: false,
  startDate: false,
  endDate: false,
};
