import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { SHIFT_CANCELLATION_REASONS, SECONDARY_REASONS, optionsForEnum } from 'helpers/enums'
import moment from 'moment';

export default ({ isOpen, toggle, onCancel, event, user }) => {
  const [error, setError] = useState({
    cancelReason: '',
    secondaryReason: '',
    otherReason: ''
  })
  const [cancelReason, setCancelReason] = useState('')
  const [secondaryReason, setSecondaryReason] = useState('')
  const [otherReason, setOtherReason] = useState('')

  const sendCioEvent = () => {
    const earliestShiftStart = Math.min(
      ...Object.values(event.shifts.byId).map(s => moment(s.startTime))
    );
    let confirmedAts = 0;
    Object.values(event.shifts.byId).forEach((shift) => {
      Object.values(shift.jobs.byId).forEach((job) => {
        if (job.currentState === 'confirmed') {
          confirmedAts++;
        }
      });
    });
    window._cio.track('weather_event_cancellations', {
      eo_id: user.id,
      eo_email: user.email,
      eo_first_name: user.firstName,
      eo_last_name: user.lastName,
      organization_name: user.companyName,
      event_name: event.title,
      event_start_date_time: moment(earliestShiftStart).format(),
      at_confirmed_count: confirmedAts,
    });
  }

  const onClick = () => {
    if (isEmpty(cancelReason)) {
      setError({ ...error, cancelReason: "This field is required" })
      return
    }

    if (['Shift Canceled', 'Position Filled'].includes(cancelReason) && isEmpty(secondaryReason)) {
      setError({ ...error, secondaryReason: "This field is required" })
      return
    }

    if (['Other'].includes(secondaryReason) && isEmpty(otherReason)) {
      setError({ ...error, otherReason: "This field is required" })
      return
    }

    if (secondaryReason === 'Weather' && window._cio) sendCioEvent();

    onCancel([cancelReason, secondaryReason, otherReason].filter(Boolean).join(` - `))
  }

  const onChange = (e) => {
    setCancelReason(e.target.value)
    setSecondaryReason('')
    setOtherReason('')
    setError({
      cancelReason: '',
      secondaryReason: '',
      otherReason: ''
    })
  }

  const onChangeSecondaryReason = (e) => {
    setSecondaryReason(e.target.value)
    setError({
      cancelReason: '',
      secondaryReason: '',
      otherReason: ''
    })
  }

  const onChangeOtherReason = (e) => {
    setOtherReason(e.target.value)
    setError({
      cancelReason: '',
      secondaryReason: '',
      otherReason: ''
    })
  }

  const handleDeleteEvent = () => {
    onCancel(cancelReason)
  };

  const handleEditEvent = () => {
    toggle();
  };

  const moreThan18HoursLeft = () => {
    const shifts = event.shifts.byId;
    const earliestShiftStart = Math.min(...Object.values(shifts).map(s => moment(s.startTime)))
    const diffInHours = moment(earliestShiftStart).diff(moment(), 'hours');
    return diffInHours >= 18;
  };

  const secondaryReasons = (cancelReason) => {
    if (cancelReason === 'Shift Canceled') {
      return moreThan18HoursLeft()
        ? SECONDARY_REASONS[cancelReason].filter(
            (reason) => reason.id !== 'Weather'
          )
        : SECONDARY_REASONS[cancelReason];
    } else return SECONDARY_REASONS[cancelReason];
  };

  return <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
    <ModalBody>
      Deleting this event will delete the entire event, all shifts associated with this event, and staff you have approved to work.
      <div className="form-group pl-0 mt-4">
        <label htmlFor="font-weight-bold">Reason<span className="text-danger">*</span></label>
        <select
          className={`form-control custom-select ${cancelReason ? '' : "text-secondary"}`}
          name="reason" value={cancelReason}
          onChange={onChange}
          required
        >
          {optionsForEnum(SHIFT_CANCELLATION_REASONS, 'Select a reason')}
        </select>
        {error.cancelReason && (
          <div className="text-danger mt-2 w-100">
            {error.cancelReason}
          </div>
        )}
        {['Shift Canceled', 'Position Filled'].includes(cancelReason) && (
          <div className="form-group pl-0 mt-4">
            <label htmlFor="font-weight-bold">Secondary Reason<span className="text-danger">*</span></label>
            <select
              className="form-control custom-select"
              name="secondaryReason" value={secondaryReason}
              onChange={onChangeSecondaryReason}
              required
            >
              {optionsForEnum(secondaryReasons(cancelReason), 'Select a secondary reason')}
            </select>
            {secondaryReason === 'Other' && (
              <div className="mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="otherReason" value={otherReason}
                  onChange={onChangeOtherReason}
                  required
                />
              </div>
            )}
          </div>
        )}
        {error.secondaryReason && (
          <div className="text-danger mt-2 w-100">
            {error.secondaryReason}
          </div>
        )}
        {cancelReason === 'Incorrectly Posted' && (
          <div>
            <p className="text-danger mt-2">
              Instead of deleting this event, learn how to edit it <a href="https://support.go4.io/hc/en-us/articles/13179441878285" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
            <div className="mt-4">
              <button className="btn btn-primary mr-2" onClick={handleEditEvent}>EDIT EVENT</button>
              <button className="btn btn-primary btn-danger heading-font" onClick={handleDeleteEvent}>DELETE EVENT</button>
            </div>
          </div>
        )}
        {cancelReason === 'No Applicants' && (
          <div>
            <p className="text-danger mt-2">
              For future job postings, we suggest posting at minimum 21 days before the start date and at minimum use the state’s average pay rate.
            </p>
            <div className="mt-4">
              <button className="btn btn-primary btn-danger heading-font" onClick={handleDeleteEvent}>DELETE EVENT</button>
            </div>
          </div>
        )}
      </div>
    </ModalBody>
    <ModalFooter><div />
      {cancelReason !== 'Incorrectly Posted' && cancelReason !== 'No Applicants' && (
        <button
          className={
            classnames(
              "btn btn-primary btn-danger heading-font",
              {
                disabled: isEmpty(cancelReason) || (['Shift Canceled', 'Position Filled'].includes(cancelReason) && isEmpty(secondaryReason)),
                disabled: isEmpty(secondaryReason) || (['Other'].includes(secondaryReason) && isEmpty(otherReason))
              }
            )}
          type="button"
          onClick={onClick}
        >
          yes, delete event
        </button>
      )}
    </ModalFooter>
  </Modal>
}
