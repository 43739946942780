import React, { Component } from 'react';
import { SHOW_TOOLTIP_COLUMNS, createOverflowChecker } from 'helpers/dashboard';
import { UncontrolledTooltip } from 'reactstrap';
import { hour24ToMeridiem, MILITARY_FORMAT } from 'helpers/datetime';
import { formatCurrency } from 'helpers/dashboard';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import moment from 'moment-timezone';
import classNames from 'classnames';
import JobActions from '../EventList/JobActions';
import { getStateStatus } from '../EventList/JobActions/JobActions';
import { getTimezone } from 'helpers/post';
import applied from 'all-applied.svg';

class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.jobsForShift.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'jobsForShift',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.jobsForShift.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  displayNewDate = () => {
    const { job } = this.props;
    const startDate = moment(job.startDate, MILITARY_FORMAT).format('MM/DD/YY');
    const endDate = moment(job.endDate, MILITARY_FORMAT).format('MM/DD/YY');

    if (moment(job.startDate, MILITARY_FORMAT).isSame(moment(job.endDate, MILITARY_FORMAT))) {
      return `New Date: ${startDate} `;
    }

    return `New Date: ${startDate} - ${endDate} `;
  };

  showUpdatedJob = (type) => {
    const { job, shift } = this.props;

    if (!['confirmed', 'checked_in'].includes(job.currentState)) return false;

    const tz = getTimezone(shift.eventLocation);
    const shiftStartDate = moment.tz(shift.startTime, tz).format('MM-DD-YYYY');
    const endStartDate = moment.tz(shift.endTime, tz).format('MM-DD-YYYY');
    const shiftStart = moment.tz(shift.startTime, tz).format('HH:mm');
    const shiftEnd = moment.tz(shift.endTime, tz).format('HH:mm');

    if (type === 'time') return job.start !== shiftStart || job.end !== shiftEnd;

    if (type === 'date') return job.startDate !== shiftStartDate || job.endDate !== endStartDate;

    if (type === 'rate')
      return (
        Number(job.payRate) !== Number(shift.payRate) ||
        Number(job.rateTypeId) !== Number(shift.rateTypeId)
      );

    return false;
  };

  render() {
    const { job, pendingApplicants, shift, from, allApplied } = this.props;
    const status = getStateStatus(job.currentState);
    const { user } = job;

    return (
      <div className="shift-job">
        <div className="col-3 add-padding">
          <div className="at-name" id={`atName-${job.id}`} ref={this.columnRefs['atName']}>
            {user.name}
            {this.state.isOverflowing.atName && (
              <UncontrolledTooltip target={`atName-${job.id}`}>{user.name}</UncontrolledTooltip>
            )}
          </div>
          {allApplied && (
            <React.Fragment key={`at-all-${job.id}`}>
              <img
                id={`at-all-${job.id}`}
                className="all-applied-icon"
                src={applied}
                alt="applied-icon"
              />
              <UncontrolledTooltip target={`at-all-${job.id}`}>
                {user.name} has applied to all shifts at this location.
              </UncontrolledTooltip>
            </React.Fragment>
          )}
        </div>
        <div className={`col-2 ${status.statusColor} teal add-padding`}>{status.name}</div>
        <div className="col-5 shift-job-actions">
          <JobActions job={job} pendingApplicants={pendingApplicants} shift={shift} from={from} />
        </div>
        <div className="col-2 expire-text add-padding">
          <div className="new-updated-job">
            <p
              id={`date-${job.id}`}
              ref={this.columnRefs['date']}
              className={classNames({
                hidden: !this.showUpdatedJob('date'),
              })}
            >
              <React.Fragment>
                <span>/</span> {this.displayNewDate()}
                {this.state.isOverflowing.date && (
                  <UncontrolledTooltip target={`date-${job.id}`}>
                    {this.displayNewDate()}
                  </UncontrolledTooltip>
                )}
              </React.Fragment>
            </p>
            <p
              id={`time-${job.id}`}
              ref={this.columnRefs['time']}
              className={classNames({ hidden: !this.showUpdatedJob('time') })}
            >
              <span>/</span>{' '}
              {`New Time: ${hour24ToMeridiem(job.start)} - ${hour24ToMeridiem(job.end)} `}
              {this.state.isOverflowing.time && (
                <UncontrolledTooltip target={`time-${job.id}`}>
                  {`New Time: ${hour24ToMeridiem(job.start)} - ${hour24ToMeridiem(job.end)}`}
                </UncontrolledTooltip>
              )}
            </p>
            <p
              id={`rate-${job.id}`}
              ref={this.columnRefs['rate']}
              className={classNames({ hidden: !this.showUpdatedJob('rate') })}
            >
              <span>/</span>{' '}
              {`New Rate: ${formatCurrency({
                currency: job.payRate,
                dollarSign: true,
              })}
              /
              ${rateTypeNameFinder(this.props.rateTypes, job.rateTypeId)} `}
              {this.state.isOverflowing.rate && (
                <UncontrolledTooltip target={`rate-${job.id}`}>
                  {`New Rate: ${formatCurrency({
                    currency: job.payRate,
                    dollarSign: true,
                  })}
                  /
                  ${rateTypeNameFinder(this.props.rateTypes, job.rateTypeId)} `}
                </UncontrolledTooltip>
              )}
            </p>
          </div>
          <p
            id={`expiration-date-${job.id}`}
            ref={this.columnRefs['expirationDate']}
            className={classNames('expiration-date')}
          >
            {job.currentState === 'pending' && `/ App Expires: ${job.expiresAt}`}
          </p>
          {job.currentState === 'pending' && this.state.isOverflowing.expirationDate && (
            <UncontrolledTooltip target={`expiration-date-${job.id}`}>
              {`/ App Expires: ${job.expiresAt}`}
            </UncontrolledTooltip>
          )}
        </div>
      </div>
    );
  }
}

export default Job;
