import { combineEpics } from 'redux-observable';
import { flatMap, values } from 'lodash';

import * as aggregateEventEpics from './AggregateInfo/epics';
import * as blogEpics from './Blog/epics';
import * as createEventEpics from './CreateEvent/epics';
import * as paymentEpics from './Payment/epics';
import * as eventEpics from './Event/epics';
import * as enumEpics from './Enum/epics';
import * as credentials from './Credentials/epics';
import * as flashEpics from './Flash/epics';
import * as jobEpics from './Job/epics';
import * as jobsAvailableEpics from './JobsAvailable/epics';
import * as registrationEpics from './Registration/epics';
import * as sessionEpics from './Session/epics';
import * as resetPasswordEpics from './ResetPassword/epics';
import * as userEpics from './User/epics';
import * as medicalRecordWaivers from './MedicalRecordWaivers/epics';
import * as medicalRecord from './MedicalRecord/epics';
import * as postEpic from './Post/epics';
import * as eapEpics from './Post/EAP/epics';
import * as dashboardEpics from './Dashboard/epics';
import * as shiftEpics from './Post/Shifts/epics';

const combined = (...epics) => flatMap(epics, values);

const epics = combined(
  aggregateEventEpics,
  blogEpics,
  createEventEpics,
  enumEpics,
  credentials,
  eventEpics,
  flashEpics,
  jobEpics,
  jobsAvailableEpics,
  paymentEpics,
  registrationEpics,
  resetPasswordEpics,
  sessionEpics,
  userEpics,
  medicalRecordWaivers,
  medicalRecord,
  postEpic,
  eapEpics,
  dashboardEpics,
  shiftEpics
);

export const rootEpic = combineEpics(...epics);
