import React from 'react';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import { formatTableDateTz, formatTimeTz } from 'helpers/datetime';
import zipcode_to_timezone from 'zipcode-to-timezone'
import JobsForShift from 'components/V2/JobsForShift';

const ShiftJobComponent = (props) => {
  const { shifts, isOpen } = props;

  if (!shifts.length && isOpen) {
    props.handleExpand();
    return null;
  }

  return (
    <div className='applicants-list'>
      {
        shifts.map((shift, idx) => {
          const shiftLocation = shift.eventLocation;
          const tz = zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) || moment.tz.guess();
          const jobs = shift.jobs;

          if (!jobs.length) return null;

          return (
            <React.Fragment key={shift.id}>
              <div className='shift-header'>
                <div>Shift <span>{idx + 1}</span></div>
                <div>Date: <span>{formatTableDateTz(shift.startTime, tz)}</span></div>
                <div>Time: <span>{`${formatTimeTz(shift.startTime, tz)} - ${formatTimeTz(shift.endTime, tz)}`}</span></div>
                <div>Location: <span>{`${shiftLocation.address.city}, ${shiftLocation.address.state}`}</span></div>
                <div>Venue: <span>{shiftLocation.name}</span></div>
              </div>
              <JobsForShift
                jobs={sortBy(jobs, 'appliedDate')}
                shift={shift}
                from="applicants"
              />
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

export default ShiftJobComponent;
