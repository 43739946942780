import { Validator } from 'redux/ducks/helpers';
import { omit } from 'lodash';

// Constants
// ========================================================
const CARD_VALIDATIONS = {
  firstName: { required: true },
  lastName: { required: true },
  addressLine1: { required: true },
  addressCity: { required: true },
  addressState: { required: true },
  addressZip: { required: true, minLength: 5, maxLength: 5, ofType: 'number' },
};

const CHECK_VALIDATIONS = {
  plaid: {
    token: { required: true },
    account_id: { required: true },
  },
};

const validationTemplate = (config) => {
  return {
    allValidated: false,
    errors: {},
    valid: false,
    validator: new Validator(config),
  };
};

// Actions
// ========================================================
export const RESET = 'go4ellis/Payment/RESET';
export const RESET_CARD = 'go4ellis/Payment/RESET_CARD';
export const RESET_CHECK = 'go4ellis/Payment/RESET_CHECK';
export const RETRIEVE_SOURCE = 'go4ellis/Payment/RETRIEVE_SOURCE';
export const RETRIEVE_SOURCE_SUCCESS = 'go4ellis/Payment/RETRIEVE_SOURCE_SUCCESS';
export const RETRIEVE_SOURCE_ERROR = 'go4ellis/Payment/RETRIEVE_SOURCE_ERROR';
export const SUBMIT = 'go4ellis/Payment/SUBMIT';
export const SUBMIT_ERROR = 'go4ellis/Payment/SUBMIT_PAYMENT_ERROR';
export const SUBMIT_SUCCESS = 'go4ellis/Payment/SUBMIT_PAYMENT_SUCCESS';
export const UPDATE_CARD_FIELD = 'go4ellis/Payment/UPDATE_CARD_FIELD';
export const UPDATE_CHECK_FIELD = 'go4ellis/Payment/UPDATE_CHECK_FIELD';
export const UPDATE_FIELD = 'go4ellis/Payment/UPDATE_FIELD';
export const UPDATE_TOKEN = 'go4ellis/Payment/UPDATE_TOKEN';
export const UPDATE_PLAID_TOKEN = 'go4ellis/Payment/UPDATE_PLAID_TOKEN';

export const CREATE_PLAID_ACCESS_TOKEN = 'go4ellis/Payment/CREATE_PLAID_ACCESS_TOKEN';
export const CREATE_PLAID_ACCESS_TOKEN_SUCCESS =
  'go4ellis/Payment/CREATE_PLAID_ACCESS_TOKEN_SUCCESS';
export const CREATE_PLAID_ACCESS_TOKEN_ERROR = 'go4ellis/Payment/CREATE_PLAID_ACCESS_TOKEN_ERROR';

export const GET_PLAID_PUBLIC_TOKEN = 'go4ellis/Payment/GET_PLAID_PUBLIC_TOKEN';
export const GET_PLAID_PUBLIC_TOKEN_SUCCESS = 'go4ellis/Payment/GET_PLAID_PUBLIC_TOKEN_SUCCESS';
export const GET_PLAID_PUBLIC_TOKEN_ERROR = 'go4ellis/Payment/GET_PLAID_PUBLIC_TOKEN_ERROR';

export const VALIDATE_CARD_FIELD = 'go4ellis/Payment/VALIDATE_CARD_FIELD';
export const VALIDATE_CHECK_FIELD = 'go4ellis/Payment/VALIDATE_CHECK_FIELD';

// Reducer Functions
// ========================================================
function loadingSourceReducer(state) {
  return {
    ...state,
    loadingSource: true,
  };
}

const resetReducer = () => new DefaultState();

const resetCardReducer = (state) => ({
  ...state,
  card: {},
  validation: {
    ...state.validation,
    card: validationTemplate(CARD_VALIDATIONS),
  },
});

const resetCheckReducer = (state) => ({
  ...state,
  check: {
    plaid: {
      token: null,
      account_id: null,
    },
  },
  validation: {
    ...state.validation,
    check: validationTemplate(CHECK_VALIDATIONS),
  },
});

const retrieveSourceReducer = (state, source) => ({
  ...omit(state, 'publicToken'),
  object: source.object ? source.object : 'card',
  source: source,
  success: false,
  loadedSource: true,
});

const submitReducer = (state, source) =>
  source
    ? {
        ...resetCheckReducer({ ...resetCardReducer(state) }),
        success: true,
        object: source.object ? source.object : state.object,
        source: source,
        token: null,
      }
    : {
        ...resetCheckReducer({ ...resetCardReducer(state) }),
        success: true,
        token: null,
      };

const updateCardFieldReducer = (state, payload) => {
  let card = { ...state.card };
  card[payload.field] = payload.value;

  return {
    ...state,
    card: card,
  };
};

const updateCheckFieldReducer = (state, payload) => {
  let check = { ...state.check };
  check[payload.field] = payload.value;

  return {
    ...state,
    check: check,
  };
};

const updateFieldReducer = (state, { field, value }) => ({
  ...state,
  ...(field === 'object'
    ? value === 'card'
      ? resetCheckReducer(state)
      : resetCardReducer(state)
    : {}),
  [field]: value,
});

const updateTokenReducer = (state, payload) => ({ ...state, token: payload });

const updatePlaidTokenReducer = (state, payload) => ({ ...state, check: { plaid: payload } });

const validateCardFieldReducer = (state, field) => {
  const validation = { ...state.validation.card };
  validation['errors'] = validation.validator.validate(state.card, field);
  validation['allValidated'] = validation.validator.allValidated();
  validation['valid'] = Object.keys(validation['errors']).length === 0 && validation.allValidated;

  return {
    ...state,
    validation: { ...state.validation, card: validation },
  };
};

const validateCheckFieldReducer = (state, field) => {
  const validation = { ...state.validation.check };
  validation['errors'] = validation.validator.validate(state.check, field);
  validation['allValidated'] = validation.validator.allValidated();
  validation['valid'] = Object.keys(validation['errors']).length === 0 && validation.allValidated;

  return {
    ...state,
    validation: { ...state.validation, check: validation },
  };
};

const getPlaidPublicTokenReducer = (state, payload) => ({
  ...state,
  object: !!payload.public_token ? 'bank_account' : 'card',
  publicToken: payload.public_token,
  loadingSource: false,
});

// Action Creators
// ========================================================
export function reset() {
  return {
    type: RESET,
  };
}

export function resetCard() {
  return {
    type: RESET_CARD,
  };
}

export function resetCheck() {
  return {
    type: RESET_CHECK,
  };
}

export function retrieveSource(userId) {
  return {
    type: RETRIEVE_SOURCE,
    payload: userId,
  };
}

export function submit(userId, token, plaid) {
  return {
    type: SUBMIT,
    payload: { id: userId, token: token, plaid: plaid },
  };
}

export function updateCardField(field, value) {
  return {
    type: UPDATE_CARD_FIELD,
    payload: { field: field, value: value },
  };
}

export function updateCheckField(field, value) {
  return {
    type: UPDATE_CHECK_FIELD,
    payload: { field: field, value: value },
  };
}

export function updateField(field, value) {
  return {
    type: UPDATE_FIELD,
    payload: { field: field, value: value },
  };
}

export function updateToken(token) {
  return {
    type: UPDATE_TOKEN,
    payload: token,
  };
}

export function updatePlaidToken(token, account_id) {
  return {
    type: UPDATE_PLAID_TOKEN,
    payload: {
      token,
      account_id,
    },
  };
}

export function createPlaidAccessToken(token, account_id, id) {
  return {
    type: CREATE_PLAID_ACCESS_TOKEN,
    payload: {
      token,
      account_id,
      id,
    },
  };
}

export function validateCardField(field) {
  return {
    type: VALIDATE_CARD_FIELD,
    payload: field,
  };
}

export function validateCheckField(field) {
  return {
    type: VALIDATE_CHECK_FIELD,
    payload: field,
  };
}

// Reducer
// ========================================================
export class DefaultState {
  object = 'card';
  card = {};
  check = {
    plaid: {
      token: null,
      account_id: null,
    },
  };
  loadedSource = false;
  loadingSource = false;
  source = {};
  stripeTerms = false;
  success = false;
  token = null;
  validation = {
    card: validationTemplate(CARD_VALIDATIONS),
    check: validationTemplate(CHECK_VALIDATIONS),
  };
}

export default function reducer(state = new DefaultState(), action = {}) {
  switch (action.type) {
    case RESET:
      return resetReducer();
    case RESET_CARD:
      return resetCardReducer(state);
    case RESET_CHECK:
      return resetCheckReducer(state);
    case RETRIEVE_SOURCE:
      return loadingSourceReducer(state);
    case RETRIEVE_SOURCE_SUCCESS:
      return retrieveSourceReducer(state, action.payload);
    case SUBMIT_SUCCESS:
      return submitReducer(state, action.payload);
    case UPDATE_CARD_FIELD:
      return updateCardFieldReducer(state, action.payload);
    case UPDATE_CHECK_FIELD:
      return updateCheckFieldReducer(state, action.payload);
    case UPDATE_FIELD:
      return updateFieldReducer(state, action.payload);
    case UPDATE_TOKEN:
      return updateTokenReducer(state, action.payload);
    case UPDATE_PLAID_TOKEN:
      return updatePlaidTokenReducer(state, action.payload);
    case VALIDATE_CARD_FIELD:
      return validateCardFieldReducer(state, action.payload);
    case VALIDATE_CHECK_FIELD:
      return validateCheckFieldReducer(state, action.payload);
    case GET_PLAID_PUBLIC_TOKEN_SUCCESS:
      return getPlaidPublicTokenReducer(state, action.payload);
    case RETRIEVE_SOURCE_ERROR:
    case GET_PLAID_PUBLIC_TOKEN_ERROR:
      return { ...state, loadingSource: false, publicToken: null };
    default:
      return state;
  }
}
